import { useState } from 'react'

import { Modal } from '@material-ui/core'
import { withLocalize } from 'react-localize-redux'
import styles from './ReportCalibrationModalContainer.module.scss'
import ReportCalibrationModal from '../components/ReportCalibrationModalForm'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { requestFile } from 'utils/request'

const ReportCalibrationModalContainer = props => {
  const [open] = useState(true)
  const { hideModal, systems, user } = props
  const systemsByGroup = _.groupBy(systems, 'group')
  const handleSubmit = async data => {
    const now = new Date()
    if (props.isSubmitting) return
    let submitData = {
      name: data.name,
      systems: data.deviceId,
      reportType: data.reportType,
      language: user.toJS().language,
      todaysDate:
        user.toJS().language === 'es'
          ? now.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
            ' ' +
            now.toLocaleTimeString('es-ES', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false
            })
          : now.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) +
            ' ' +
            now.toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false
            })
    }
    await requestFile(`reports/calibrationReport/`, {
      method: 'POST',
      data: {
        ...submitData
      }
    }).then(async response => {
      const fileUrl = response.headers.get('fileUrl')

      await response.blob().then(blob => {
        // Setting various property values
        const alink = document.createElement('a')
        alink.href = fileUrl
        alink.download = submitData.name + '.pdf'
        alink.click()
      })

      hideModal()
    })

    return handleSubmit
  }

  return (
    <Modal
      open={open}
      onClose={hideModal}
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
    >
      <div className={styles.modalContainer}>
        <ReportCalibrationModal
          {...props}
          systems={systems}
          systemsByGroup={systemsByGroup}
          onSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}
ReportCalibrationModalContainer.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired
}

export default withLocalize(ReportCalibrationModalContainer)
