import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { store } from 'index'
import { injectReducer } from 'common/reducers'
import { injectSaga } from 'common/sagas'
import { getModalType, getModalProps } from 'common/selectors/modal'
import { hideModal } from 'common/actions/modal'

import * as ModalTypes from 'constants/ModalTypes'

import AddSystemModalReducer from 'modules/modals/AddSystemModal/reducers'
import EditSystemLigthsReducer from 'modules/modals/EditSystemLights/reducers'
import EditSystemPeriodicityReducer from 'modules/modals/EditSystemPeriodicity/reducers'
import AddParameterAlertModalReducer from 'modules/modals/AddParameterAlertModal/reducers'
import AddInactivityAlertModalReducer from 'modules/modals/AddInactivityAlertModal/reducers'
import EditParameterAlertModalReducer from 'modules/modals/EditParameterAlertModal/reducers'
import EditInactivityAlertModalReducer from 'modules/modals/EditInactivityAlertModal/reducers'
import UploadFileModalReducer from 'modules/modals/UploadFile/reducers'
import NewGroupModalReducer from 'modules/modals/NewGroup/reducers'
import TransferSystemReducer from 'modules/modals/TransferSystem/reducers'
import EditGroupModalReducer from 'modules/modals/EditGroup/reducers'
import SupportModalReducer from 'modules/modals/SupportModal/reducers'
import AddReportModalReducer from 'modules/modals/AddReportModal/reducers'
import DeleteDeviceUserModalReducer from 'modules/modals/DeleteDeviceUserModal/reducers'
import ConfirmTransferReducer from 'modules/modals/ConfirmTransfer/reducers'
import AddCollaborativeReducer from 'modules/modals/AddCollaborativeModal/reducers'
import EditSystemCalibrationCo2Reducer from 'modules/modals/EditSystemCalibrationCo2/reducers'
import weatherModalReducer from 'modules/modals/WeatherModal/reducers'
import Modals from 'modules/modals'

const MODAL_COMPONENT = {
  [ModalTypes.FULL_SCREEN]: Modals.FullScreenSystem,
  [ModalTypes.CONFIRMATION_MODAL]: Modals.ConfirmationModal,
  [ModalTypes.DOWNLOAD_SETTINGS]: Modals.DownloadSettings,
  [ModalTypes.DOWNLOAD_MULTIPLE]: Modals.DownloadMultipleSettings,
  [ModalTypes.DOWNLOAD_STATISTICS]: Modals.DownloadStatistics,
  [ModalTypes.ADD_SYSTEM]: Modals.AddSystemModal,
  [ModalTypes.EDIT_SYSTEM]: Modals.EditSystemModal,
  [ModalTypes.EDIT_LIGHTS]: Modals.EditSystemLights,
  [ModalTypes.EDIT_PERIODICITY]: Modals.EditSystemPeriodicity,
  [ModalTypes.EDIT_CALIBRATIONCO2]: Modals.EditSystemCalibrationCo2,
  [ModalTypes.ADD_PARAMETER]: Modals.AddParameterAlertModal,
  [ModalTypes.ADD_INACTIVITY]: Modals.AddInactivityAlertModal,
  [ModalTypes.EDIT_PARAMETER]: Modals.EditParameterAlertModal,
  [ModalTypes.EDIT_INACTIVITY]: Modals.EditInactivityAlertModal,
  [ModalTypes.ADD_FULLSCREEN]: Modals.AddFullScreenView,
  [ModalTypes.EDIT_FULLSCREEN]: Modals.EditFullScreenView,
  [ModalTypes.NEW_GROUP]: Modals.NewGroupModal,
  [ModalTypes.TRANSFER_SYSTEM]: Modals.TransferSystem,
  [ModalTypes.EDIT_GROUP]: Modals.EditGroupModal,
  [ModalTypes.UPLOAD_FILE]: Modals.UploadFileModal,
  [ModalTypes.TYPE_INFO]: Modals.TypeInfoModal,
  [ModalTypes.SUPPORT_MODAL]: Modals.SupportModal,
  [ModalTypes.SMART_REPORT]: Modals.AddReportModal,
  [ModalTypes.DELETE_USER_SYSTEM]: Modals.DeleteDeviceUser,

  [ModalTypes.WHATS_NEW]: Modals.WhatIsNewModal,
  [ModalTypes.DOWNLOAD_WELL_DATA_REPORT]: Modals.DownloadWellDataReport,
  [ModalTypes.DOWNLOAD_WELL_STATIC_DATA]: Modals.DownloadWellStaticData,
  [ModalTypes.CONFIRM_TRANSFER]: Modals.ConfirmTransfer,
  [ModalTypes.ADD_COLLABORATIVE]: Modals.AddCollaborativeModal,
  [ModalTypes.SHOW_MESSAGE]: Modals.ShowMessage,
  [ModalTypes.CALIBRATION_REPORT]: Modals.ReportCalibrationModal,
  [ModalTypes.WEATHER_MODAL]: Modals.WeatherModal
}

const MODAL_REDUCERS = {
  [ModalTypes.ADD_SYSTEM]: AddSystemModalReducer,
  [ModalTypes.EDIT_LIGHTS]: EditSystemLigthsReducer,
  [ModalTypes.EDIT_PERIODICITY]: EditSystemPeriodicityReducer,
  [ModalTypes.EDIT_CALIBRATIONCO2]: EditSystemCalibrationCo2Reducer,
  [ModalTypes.ADD_PARAMETER]: AddParameterAlertModalReducer,
  [ModalTypes.ADD_INACTIVITY]: AddInactivityAlertModalReducer,
  [ModalTypes.EDIT_PARAMETER]: EditParameterAlertModalReducer,
  [ModalTypes.EDIT_INACTIVITY]: EditInactivityAlertModalReducer,
  [ModalTypes.UPLOAD_FILE]: UploadFileModalReducer,
  [ModalTypes.NEW_GROUP]: NewGroupModalReducer,
  [ModalTypes.TRANSFER_SYSTEM]: TransferSystemReducer,
  [ModalTypes.DELETE_USER_SYSTEM]: DeleteDeviceUserModalReducer,
  [ModalTypes.EDIT_GROUP]: EditGroupModalReducer,
  [ModalTypes.SUPPORT_MODAL]: SupportModalReducer,
  [ModalTypes.SMART_REPORT]: AddReportModalReducer,
  [ModalTypes.CONFIRM_TRANSFER]: ConfirmTransferReducer,
  [ModalTypes.ADD_COLLABORATIVE]: AddCollaborativeReducer,
  [ModalTypes.WEATHER_MODAL]: weatherModalReducer
}

const MODAL_SAGAS = {
  // Empty
}

const typeToKey = type => {
  let key = type.toLowerCase()

  if (key.indexOf('_') !== -1) {
    const indexUnderScore = key.indexOf('_')
    const firstCharAfterUnderscore = key.charAt(key.indexOf('_') + 1)

    key = key.replace('_', '')
    key =
      key.substr(0, indexUnderScore) +
      firstCharAfterUnderscore.toUpperCase() +
      key.substr(indexUnderScore + 1)
  }
  return key
}

export class ModalRoot extends PureComponent {
  static propTypes = {
    type: PropTypes.string,
    props: PropTypes.object,
    hideModal: PropTypes.func.isRequired
  }

  render() {
    if (!this.props.type) return null

    const { type, props, hideModal } = this.props
    const ModalComponent = MODAL_COMPONENT[type]
    const reducer = MODAL_REDUCERS[type]
    const sagas = MODAL_SAGAS[type]

    if (reducer) {
      injectReducer(store, { key: typeToKey(type), reducer })
    }

    if (sagas) {
      injectSaga(store, { key: typeToKey(type), saga: sagas })
    }

    return (
      <ModalComponent
        {...props}
        hideModal={hideModal}
      />
    )
  }
}

const mapStateToProps = state => ({
  type: getModalType(state),
  props: getModalProps(state)
})

const mapActionsToProps = {
  hideModal
}

export default connect(mapStateToProps, mapActionsToProps)(ModalRoot)
