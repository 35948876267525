import { useState } from 'react'
import cx from 'classnames'

import styles from './WeatherModalForm.module.scss'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import { getUser } from 'common/selectors/user'

import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'

import _ from 'lodash'

import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import MapModal from 'modules/modals/MapModal'

import Paper from '@material-ui/core/Paper'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { LoadingButton } from '@mui/lab'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'

import {
  Typography,
  ListItem,
  FormControlLabel,
  Collapse,
  ListItemText,
  Radio,
  InputAdornment,
  TextField,
  Button
} from '@material-ui/core'
import Checkbox from '@mui/material/Checkbox'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { MAPBOX_API_KEY } from 'config'
const MAP_TOKEN = MAPBOX_API_KEY

const weatherModalForm = props => {
  const {
    system,
    systems,
    translate,
    systemsByGroup,
    isSubmitting,
    submitError,
    resetError,
    onSubmit
  } = props
  let { weather } = system
  const useStyles = makeStyles(() => ({
    input: {
      [`& fieldset`]: {
        borderRadius: 30,
        borderColor: 'grey'
      },
      '& label.Mui-focused': {
        color: blue[600]
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'grey'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'grey'
        },
        '&:hover fieldset': {
          borderColor: 'grey'
        },
        '&.Mui-focused fieldset': {
          borderColor: blue[600]
        }
      }
    },
    switch: {
      '& .Mui-checked': {
        color: blue[600]
      },
      '& .MuiSwitch-colorSecondary': {
        color: 'rgba(255,255,255)'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: 'rgba(0,155,229,1)'
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: blue[600],
        color: blue[600]
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
        color: blue[600]
      },
      '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
        backgroundColor: blue[100]
      },
      '& .MuiSwitch-thumb': {
        transform: 'translateY(-3px)',
        width: '21px',
        height: '21px'
      },
      '& .MuiSwitch-track': {
        backgroundColor: 'rgba(33,33,33, 0.4)'
      }
    },
    paper: {
      border: 'none',
      boxShadow: 'none'
    },
    wrapper: {
      width: '100%'
    }
  }))

  const error = () => {
    if (!submitError) return null

    let message = translate('submitErrors.unknown')
    if (submitError.res && submitError.res.status === 409) {
      message = translate('submitErrors.locationNotFound')
    }

    return (
      <Alert
        severity='error'
        className={styles.showError}
      >
        {message}
      </Alert>
    )
  }
  const classes = useStyles()
  const [activeTab, setActiveTab] = useState(0)
  const [weatherOption, setWeatherOption] = useState(weather ? 'selectMap' : '')

  const [location, setLocation] = useState(
    weather
      ? system.lat
        ? { latitude: Number(system.lat), longitude: Number(system.lon) }
        : { latitude: Number(weather.lat), longitude: Number(weather.lon) }
      : null
  )

  const [openMap, setOpenMap] = useState(false)
  const [selectDeviceNumber, setSelectDeviceNumber] = useState('onlyOneDevice')
  const [selectedDevices, setSelectedDevices] = useState([system._id])
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [open, setOpen] = useState({})
  const [locationDenied, setLocationDenied] = useState(false)
  const [fullAdress, setFullAdress] = useState(
    weather
      ? system.address
        ? system.address
        : weather.address
        ? weather.address
        : translate('noInformation')
      : translate('noInformation')
  )
  const [address, setAddress] = useState(weather ? system?.address : translate('noInformation'))
  const { handleSubmit, register, errors, clearErrors, setError } = useForm()

  const handleChangeOption = event => {
    resetError()

    if (event.target.value === 'currentLocation') {
      setLocation(null)
      handleGetLocation()
      setFullAdress(translate('noInformation'))
    } else {
      setLocation(null)
      setFullAdress(translate('noInformation'))
    }
    setWeatherOption(event.target.value)
  }
  const handleChangedeviceNumber = event => {
    setSelectDeviceNumber(event.target.value)
    if (event.target.value === 'onlyOneDevice') {
      setSelectedDevices([system._id])
    } else {
      setSelectedDevices([])
    }
  }

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async position => {
          const { latitude, longitude } = position.coords
          const response = await axios.get(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAP_TOKEN}`
          )
          const data = response.data
          if (data && data.features && data.features.length > 0) {
            const location = data.features[0]
            setAddress(location.place_name)
          }
          setLocation({ latitude, longitude })
        },
        error => {
          if (error) {
            setLocationDenied(true)
          }
        }
      )
    }
  }
  const onClickCancel = () => {
    props.hideModal()
  }
  const onClickNext = () => {
    if (selectedDevices.length === 0) {
      setError('selectedDevices', { shouldFocus: 'selectedDevices' })
    } else {
      setActiveTab(1)
    }
  }
  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
      clearErrors('selectedDevices')
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }
  const handleSelectAllGroupDevices = (event, systems) => {
    if (event.target.checked) {
      const aux = []
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) aux.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }
  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setSelectedDevices([])
    } else {
      const systemIds = []
      systems.map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }
  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }
  const onClickBack = () => {
    setActiveTab(0)
  }

  const onClickSave = () => {
    const data = {}

    data.location = location
    data.devices = selectedDevices
    data.address = address

    onSubmit(data)
  }
  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('externalWeather')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderSelected}>
                    {translate('navWeatherDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('navWeatherLocation')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>{translate('applyLocation')}</Typography>
                </div>
                <FormControlLabel
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  className={styles.checkboxFormSpaces}
                  value={'onlyOneDevice'}
                  onChange={handleChangedeviceNumber}
                  control={
                    <Radio
                      className={cx(styles.checkbox, styles.radioItem)}
                      classes={{ checked: styles.checkedBox }}
                      inputRef={register()}
                      disableRipple
                      checked={selectDeviceNumber === 'onlyOneDevice'}
                      value={'onlyOneDevice'}
                    />
                  }
                  label={translate('onlyOneDevice')}
                />
                <FormControlLabel
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  className={styles.checkboxForm}
                  value={'multipleDevice'}
                  onChange={handleChangedeviceNumber}
                  control={
                    <Radio
                      className={cx(styles.checkbox, styles.radioItem)}
                      classes={{ checked: styles.checkedBox }}
                      inputRef={register()}
                      disableRipple
                      checked={selectDeviceNumber === 'multipleDevice'}
                      value={'multipleDevice'}
                    />
                  }
                  label={translate('multipleDevice')}
                />
                <TextField
                  variant='outlined'
                  fullWidth
                  type='search'
                  onChange={filterExpressionChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  id='searchDevice'
                  name='searchDevice'
                  placeholder={translate('searchDevice')}
                  className={cx(styles.input, classes.input)}
                />
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAllDevices'
                      id='selectAllDevices'
                      indeterminate={isIndeterminateDevices()}
                      onChange={handleSelectAllDevices}
                      checked={isCheckAllDevices}
                      disabled={selectDeviceNumber !== 'multipleDevice'}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <Paper
                  className={styles.devicesListPaper}
                  classes={{ root: classes.paper }}
                >
                  <List
                    disableTouchRipple
                    className={styles.devicesList}
                  >
                    {_.map(systemsGroupState, function (systems, group) {
                      const systemsType = _.groupBy(systems, 'micaType')
                      return (
                        <div className={styles.noGroupWrapper}>
                          {group !== 'undefined' && (
                            <div>
                              <ListItem
                                button
                                disableTouchRipple
                                className={styles.groupListItem}
                              >
                                {!open[group] ? (
                                  <ExpandLess
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                ) : (
                                  <ExpandMore
                                    onClick={() => handleClick(group)}
                                    className={styles.expandIcon}
                                  />
                                )}
                                <Checkbox
                                  className={cx(styles.checkbox)}
                                  classes={{ checked: styles.checkedBox }}
                                  name={group}
                                  onChange={e => handleSelectAllGroupDevices(e, systems)}
                                  id={group}
                                  disabled={selectDeviceNumber !== 'multipleDevice'}
                                />
                                <ListItemText
                                  primary={group}
                                  className={
                                    selectDeviceNumber !== 'multipleDevice'
                                      ? styles.listItemTextDisabled
                                      : styles.listItemText
                                  }
                                />
                              </ListItem>
                            </div>
                          )}
                          {group === 'undefined' && (
                            <div>
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={type}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={system._id}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                  disabled={selectDeviceNumber !== 'multipleDevice'}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </div>
                          )}

                          {group !== 'undefined' && (
                            <Collapse
                              in={!open[group]}
                              timeout='auto'
                              unmountOnExit
                              classes={{
                                wrapper: classes.wrapper
                              }}
                            >
                              {_.map(systemsType, function (systems, type) {
                                return (
                                  <div>
                                    <ListItem
                                      button
                                      disableTouchRipple
                                      className={styles.typeListItem}
                                    >
                                      {!open[type] ? (
                                        <ExpandLess
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      ) : (
                                        <ExpandMore
                                          onClick={() => handleClick(type)}
                                          className={styles.expandIcon}
                                        />
                                      )}
                                      <ListItemText
                                        primary={translate(type)}
                                        className={styles.micaTypeItemText}
                                      />
                                    </ListItem>
                                    <Collapse
                                      in={!open[type]}
                                      timeout='auto'
                                      unmountOnExit
                                    >
                                      {systems.map((system, index) => {
                                        return (
                                          <div
                                            key={'system' + index}
                                            className={styles.micaItem}
                                          >
                                            <FormControlLabel
                                              className={styles.checkboxForm}
                                              control={
                                                <Checkbox
                                                  className={cx(styles.checkbox)}
                                                  classes={{
                                                    checked: styles.checkedBox
                                                  }}
                                                  checked={selectedDevices.includes(system._id)}
                                                  onChange={handleChangeDevice}
                                                  required
                                                  outline='true'
                                                  inputRef={register()}
                                                  disableRipple
                                                  name={`selectedDevices[]`}
                                                  value={system._id}
                                                  disabled={selectDeviceNumber !== 'multipleDevice'}
                                                />
                                              }
                                              label={system.name}
                                            />
                                          </div>
                                        )
                                      })}
                                    </Collapse>
                                  </div>
                                )
                              })}
                            </Collapse>
                          )}
                        </div>
                      )
                    })}
                  </List>
                </Paper>
              </div>
              <div className={styles.wrapperError}>
                {errors.selectedDevices && (
                  <div className={styles.error}>
                    <small>{translate('validation.anyDeviceRequired')}</small>
                  </div>
                )}
              </div>
              <div className={styles.buttonWrapper}>
                <LoadingButton
                  fullWidth
                  disableRipple
                  variant='contained'
                  loadingPosition='end'
                  className={styles.buttonSiguiente}
                  onClick={onClickNext}
                >
                  {translate('next')}
                  <ArrowForward className={styles.arrow} />
                </LoadingButton>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  disableRipple
                  className={styles.buttonCancelar}
                  onClick={onClickCancel}
                >
                  {translate('cancel')}
                </Button>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheader}>
                    {translate('navWeatherDevices')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('navWeatherLocation')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>{translate('indicateAdress')}</Typography>
                </div>
                <FormControlLabel
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  className={styles.checkboxForm}
                  value={'currentLocation'}
                  onChange={handleChangeOption}
                  control={
                    <Radio
                      className={cx(styles.checkbox, styles.radioItem)}
                      classes={{ checked: styles.checkedBox }}
                      inputRef={register()}
                      disableRipple
                      checked={weatherOption === 'currentLocation'}
                      value={'currentLocation'}
                    />
                  }
                  label={translate('currentLocation')}
                />
                <FormControlLabel
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  className={styles.checkboxFormSpace}
                  value={'selectMap'}
                  onChange={handleChangeOption}
                  control={
                    <Radio
                      className={cx(styles.checkbox, styles.radioItem)}
                      classes={{ checked: styles.checkedBox }}
                      inputRef={register()}
                      disableRipple
                      checked={weatherOption === 'selectMap'}
                      value={'selectMap'}
                      disabled={weatherOption === 'currentLocation' && !location && !locationDenied}
                    />
                  }
                  label={translate('selectMap')}
                />
                <Typography className={styles.searchByAddressOrZipCode}>
                  {translate('searchByAddressOrZipCode')}
                </Typography>{' '}
                <div className={styles.mapInfo}>
                  <Typography className={styles.adressInfo}>{fullAdress}</Typography>
                </div>
                <div className={styles.openMap}>
                  <Button
                    className={styles.openMapButton}
                    disabled={weatherOption !== 'selectMap'}
                    startIcon={<MapOutlinedIcon />}
                    onClick={() => setOpenMap(true)}
                  >
                    {translate('setupLocation')}
                  </Button>
                </div>
                {openMap && (
                  <MapModal
                    hideModal={() => setOpenMap(false)}
                    translate={translate}
                    onConfirm={location => setLocation(location)}
                    initialMarkerLocation={location}
                    changeAdress={adres => {
                      setFullAdress(adres)
                      setAddress(adres)
                    }}
                    fullAdress={fullAdress}
                  />
                )}
                <div className={styles.buttonWrapper}>
                  {error()}

                  <LoadingButton
                    fullWidth
                    loadingPosition='end'
                    variant='contained'
                    onClick={onClickSave}
                    className={styles.buttonSave}
                    loading={
                      (weatherOption === 'currentLocation' && !location && !locationDenied) ||
                      isSubmitting
                    }
                    isLoading={
                      (weatherOption === 'currentLocation' && !location && !locationDenied) ||
                      isSubmitting
                    }
                    disabled={!location || isSubmitting}
                  >
                    {translate('save')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}
weatherModalForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  submitError: PropTypes.any
}
const mapStateToProps = state => ({
  user: getUser(state)
})
export default withLocalize(connect(mapStateToProps)(weatherModalForm))
