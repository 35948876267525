import { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter, browserHistory } from 'react-router'
import { getActiveLanguage, getTranslate, getLanguages } from 'react-localize-redux'

import { setActiveLanguage } from 'common/actions/localize'
import { getUser, getUserLang } from 'common/selectors/user'

import cx from 'classnames'
import styles from './Header.module.scss'
import { withStyles, StylesProvider } from '@material-ui/core/styles'

import * as Routes from 'constants/Routes'

import { AppBar, Toolbar, MenuItem, Menu, Fade, Button, Icon, SvgIcon } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

import { InbiotBigLogo, InbiotLogo } from 'icons'
import Typography from '@material-ui/core/Typography'
import { ReactComponent as ProfileLogo } from 'icons/profile.svg'

import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'
import { ReactComponent as ManageAccountsIcon } from 'icons/manage_accounts.svg'
import { ReactComponent as visLogo } from 'icons/visualisation.svg'

const navItems = [
  {
    translation: 'dashboard',
    route: Routes.HOME,
    isForManager: false
  },
  {
    translation: 'shareables',
    route: Routes.FULLSCREEN,
    isForManager: false
  },
  {
    translation: 'alerts',
    route: Routes.ALERTS,
    isForManager: true
  },
  {
    translation: 'certifications',
    route: Routes.CERTIFICATIONS,
    isForManager: true
  },
  {
    translation: 'report',
    route: Routes.REPORTS,
    isForManager: true
  }
]

const helpItems = [
  {
    translation: 'helpOptions.howMyInbiotWorks'
  },
  {
    translation: 'faqs'
  },
  {
    translation: 'helpOptions.contactUs'
  }
]

const Header = props => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [isHelpMobileMenuOpen, setHelpMobileMenuOpen] = useState(false)
  const [userAnchorEl, setUserAnchorEl] = useState(null)

  const redirect = route => {
    browserHistory.push(route)
  }

  const handleLinkClick = item => {
    closeMobileMenu()
    if (item.route !== Routes.NOT_FOUND) redirect(item.route)
  }

  const handleLogoClick = () => {
    redirect(Routes.HOME)
  }

  const handleMenuUserOpen = event => {
    if (userAnchorEl) {
      setUserAnchorEl(null)
    } else {
      setUserAnchorEl(event.currentTarget)
    }
  }

  const handleUserClick = option => {
    switch (option) {
      case 'edit-profile':
        setUserAnchorEl(null)
        redirect(Routes.PROFILE)
        break
      case 'visualization':
        setUserAnchorEl(null)
        redirect(Routes.VISUALISATION)
        break
      case 'logout':
        redirect(Routes.LOGOUT)
        break
      default:
        break
    }
  }

  const openMobileMenu = () => {
    if (!isMobileMenuOpen) {
      setMobileMenuOpen(true)
    } else {
      setMobileMenuOpen(false)
    }
  }

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  const openHelpMobileMenu = () => {
    if (!isHelpMobileMenuOpen) {
      setHelpMobileMenuOpen(true)
      setMobileMenuOpen(false)
    } else {
      setHelpMobileMenuOpen(false)
    }
  }

  const closeHelpMobileMenu = () => {
    setHelpMobileMenuOpen(false)
  }

  const userDropDown = () => {
    const StyledMenu = withStyles(styles.menuUserPaper)(props => (
      <Menu
        {...props}
        classes={{ paper: styles.menuUserPaper }}
      />
    ))

    return (
      <div className={styles.userMenuWrapper}>
        <Button
          disableRipple
          aria-controls='user-menu'
          aria-haspopup='true'
          onClick={handleMenuUserOpen}
          className={cx(styles.buttonLanguages)}
        >
          <ProfileLogo
            color='white'
            className={styles.userIcon}
          />
        </Button>
        <StyledMenu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          id='user-menu'
          anchorEl={userAnchorEl}
          keepMounted
          open={Boolean(userAnchorEl)}
          onClose={() => setUserAnchorEl(null)}
          TransitionComponent={Fade}
        >
          <MenuItem
            key='edit-profile'
            onClick={() => handleUserClick('edit-profile')}
            className={styles.menuItem}
          >
            <SvgIcon
              className={styles.userOptionsLogo}
              component={ManageAccountsIcon}
              viewBox='0 0 24 24'
            />
            <Typography className={styles.userOptionsText}>{translate('Configuration')}</Typography>
          </MenuItem>

          <MenuItem
            key='visualization'
            onClick={() => handleUserClick('visualization')}
            className={styles.menuItem}
          >
            <SvgIcon
              className={styles.userOptionsLogo}
              component={visLogo}
              viewBox='0 0 45 40'
            />
            <Typography className={styles.userOptionsText}>{translate('visualization')}</Typography>
          </MenuItem>

          <MenuItem
            key='logout'
            onClick={() => handleUserClick('logout')}
            className={styles.menuItem}
          >
            <ExitToAppOutlinedIcon className={styles.userOptionsLogo} />
            <Typography className={styles.userOptionsText}>{translate('logout')}</Typography>
          </MenuItem>
        </StyledMenu>
      </div>
    )
  }

  const { isLoggedIn, translate, user } = props
  const pathname = props.location.pathname

  return (
    <StylesProvider injectFirst>
      <AppBar className={styles.component}>
        <Toolbar className={styles.content}>
          <div className={styles.logoWrapper}>
            <Icon
              onClick={() => (isLoggedIn ? redirect(Routes.HOME) : redirect(Routes.LOGOUT))}
              className={styles.logo}
            >
              <img
                className={styles.logoImage}
                src={InbiotLogo}
                alt=''
              />
            </Icon>
          </div>
          <div className={styles.nav}>
            {isLoggedIn &&
              navItems.map((item, index) => {
                let isActive
                window.location.pathname === Routes.DASHBOARD && item.route === Routes.HOME
                  ? (isActive = Routes.HOME)
                  : (isActive = pathname === item.route)

                const collaborativeInformation = isLoggedIn && user.toJS().collaborativeInformation

                if (
                  collaborativeInformation?.length > 0 &&
                  collaborativeInformation[0]?.role === 'Viewer' &&
                  item.isForManager
                )
                  return
                return (
                  <div
                    key={index}
                    className={cx(styles.navItem, {
                      [styles.activeNavItem]: isActive
                    })}
                    onClick={() => handleLinkClick(item)}
                  >
                    <span>{translate(item.translation)}</span>
                  </div>
                )
              })}
          </div>

          <div className={styles.mobileNav}>
            {isLoggedIn && !isMobileMenuOpen && !isHelpMobileMenuOpen && (
              <MenuIcon
                onClick={openMobileMenu}
                className={styles.mobileMenuIcon}
              />
            )}

            {isLoggedIn && isMobileMenuOpen && (
              <CloseIcon
                onClick={closeMobileMenu}
                className={styles.mobileMenuIcon}
              />
            )}

            {isLoggedIn && isHelpMobileMenuOpen && (
              <CloseIcon
                onClick={closeHelpMobileMenu}
                className={styles.mobileMenuIcon}
              />
            )}

            <div
              className={cx(styles.mobileMenu, {
                [styles.mobileMenuOpen]: isMobileMenuOpen
              })}
            >
              <Fade in={isMobileMenuOpen}>
                <div
                  className={cx(styles.mobileMenuContent, {
                    [styles.mobileMenuContentClose]: !isMobileMenuOpen
                  })}
                >
                  {isLoggedIn &&
                    navItems.map((item, index) => {
                      const isActive = pathname === item.route || item.route === Routes.DASHBOARD
                      const collaborativeInformation =
                        isLoggedIn && user.toJS().collaborativeInformation

                      if (
                        collaborativeInformation?.length > 0 &&
                        collaborativeInformation[0]?.role === 'Viewer' &&
                        item.isForManager
                      )
                        return

                      return (
                        <div
                          key={index}
                          className={cx(styles.mobileNavItem, {
                            [styles.activeMobileNavItem]: isActive
                          })}
                          onClick={() => handleLinkClick(item)}
                        >
                          {translate(item.translation)}
                        </div>
                      )
                    })}

                  <hr className={styles.mobileMenuDivider} />

                  <Button
                    disableRipple
                    className={cx(styles.mobileNavItem, styles.buttonLanguages)}
                    onClick={() => openHelpMobileMenu()}
                  >
                    <Typography className={styles.helpButtonText}>{translate('help')}</Typography>
                  </Button>
                </div>
              </Fade>

              <Fade in={isHelpMobileMenuOpen}>
                <div
                  className={cx(styles.mobileMenuContent, {
                    [styles.mobileMenuContentClose]: !isHelpMobileMenuOpen
                  })}
                >
                  {isLoggedIn &&
                    helpItems.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={styles.helpMobileNavItem}
                        >
                          {translate(item.translation)}
                        </div>
                      )
                    })}
                </div>
              </Fade>
            </div>
          </div>

          <div className={styles.mobileLogo}>
            <Icon
              className={styles.logoMobile}
              onClick={handleLogoClick}
            >
              <img
                className={styles.logoImageMobile}
                src={InbiotBigLogo}
                alt=''
              />
            </Icon>
          </div>

          {isLoggedIn && !isMobileMenuOpen && userDropDown()}
        </Toolbar>
      </AppBar>
    </StylesProvider>
  )
}

Header.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: getUser(state),
  userLanguage: getUserLang(state),
  activeLanguage: getActiveLanguage(state.localize),
  languages: getLanguages(state.localize),
  translate: getTranslate(state.localize)
})

const mapActionsToProps = {
  setActiveLanguage
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Header))
