import { useState } from 'react'
import { withLocalize } from 'react-localize-redux'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import styles from './ReportCalibrationModalForm.module.scss'
import {
  Typography,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  ListItem,
  ListItemText,
  Collapse,
  Radio
} from '@material-ui/core'
import { LoadingButton } from '@mui/lab'

import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import cx from 'classnames'
import _ from 'lodash'
import { blue } from '@material-ui/core/colors'

import { ArrowForward, ArrowBack } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  inp: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-1px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  },
  MuiTimePicker: {
    styleOverrides: {
      root: {
        backgroundColor: blue[600]
      }
    }
  }
}))
const ReportCalibrationModalForm = props => {
  const classes = useStyles()

  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const { onSubmit, systems, translate, systemsByGroup } = props
  const { formState, getValues, setError, register, errors, clearErrors, handleSubmit } = useForm()
  const { isSubmitting } = formState
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [groupSelected, setGroupSelected] = useState([])
  const [open, setOpen] = useState({})
  const [dataState, setDataState] = useState({})
  const [reportNameField, setReportNameField] = useState()
  const [reportType, setReportType] = useState('regularReport')
  const [activeTab, setActiveTab] = useState(0)
  const [selectedDevices, setSelectedDevices] = useState([])
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)
  let keys = Object.keys(systemsGroupState)
  keys = keys.filter(function (element) {
    return element !== 'undefined'
  })
  const handleSelectAllGroupDevices = (event, systems) => {
    const { checked, value } = event.target
    if (checked) {
      setGroupSelected([...groupSelected, value])
    } else {
      setGroupSelected(groupSelected.filter(item => item !== value))
    }
    if (event.target.checked) {
      const aux = []
      let j = selectedDevices.length
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) {
          if (j < 50) {
            aux.push(systems[i]._id)
            j++
          }
        }
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }

  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }

  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }
  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }
  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setGroupSelected([])
      setSelectedDevices([])
    } else {
      setGroupSelected(keys)
      const systemIds = []
      systemOrdered.slice(0, 50).map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const systemOrdered = []

  _.map(systemsGroupState, function (systems, group) {
    const systemsType = _.groupBy(systems, 'micaType')

    group === 'undefined' &&
      _.map(systemsType, function (systems, type) {
        systems.map((system, index) => {
          systemOrdered.push(system)
        })
      })
    group !== 'undefined' &&
      _.map(systemsType, function (systems, type) {
        systems.map((system, index) => {
          systemOrdered.push(system)
        })
      })
  })
  const onClickCancel = () => {
    props.hideModal()
  }
  const onClickNext = () => {
    let selected = getValues()
    if (selected.selectedDevices !== undefined && !Array.isArray(selected.selectedDevices)) {
      selected.selectedDevices = [selected.selectedDevices]
    }
    if (activeTab === 0) {
      if (
        selected &&
        selected.selectedDevices !== undefined &&
        selected.selectedDevices.length > 0
      ) {
        setActiveTab(1)
      }

      if (selected.selectedDevices === undefined || selected.selectedDevices.length === 0) {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
    }
  }

  const handleReportNameChange = event => {
    setReportNameField(event.target.value)
    if (event.target.value !== '') {
      clearErrors('reportName')
    }
  }

  const handleChangeLevelTypes = event => {
    setReportType(event.target.value)
  }
  const onClickBack = () => {
    setActiveTab(0)
  }
  const onClickDownload = () => {
    if (reportNameField !== '') {
      clearErrors('reportName')
      const data = {}
      data.deviceId = selectedDevices
      data.name = reportNameField
      data.reportType = reportType

      setDataState(data)
    } else {
      setError('reportName', { shouldFocus: 'reportName' })
    }
  }

  return (
    <StylesProvider injectFirst>
      <div className={styles.form}>
        <Typography className={styles.header}>
          {translate('reportCalibrationModal.newReport')}
        </Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('reportCalibrationModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderParams}>
                    {translate('reportCalibrationModal.navDownload')}
                  </Typography>
                </div>
                <div>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='search'
                    onChange={filterExpressionChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    id='searchDevice'
                    name='searchDevice'
                    placeholder={translate('searchDevice')}
                    className={cx(styles.input, classes.input, {
                      [styles.hasError]: errors.reportName
                    })}
                  />
                  <FormControlLabel
                    className={styles.selectAllCheckbox}
                    control={
                      <Checkbox
                        className={cx(styles.checkbox)}
                        classes={{ checked: styles.checkedBox }}
                        name='selectAllDevices'
                        id='selectAllDevices'
                        indeterminate={isIndeterminateDevices()}
                        onChange={handleSelectAllDevices}
                        checked={isCheckAllDevices}
                      />
                    }
                    label={translate('reportCalibrationModal.selectDevices')}
                  />
                  <Paper className={cx(styles.devicesListPaper, classes.paper)}>
                    <List
                      disableTouchRipple
                      className={styles.devicesList}
                    >
                      {_.map(systemsGroupState, function (systems, group) {
                        const systemsType = _.groupBy(systems, 'micaType')
                        return (
                          <div className={styles.noGroupWrapper}>
                            {group !== 'undefined' && (
                              <div>
                                <ListItem
                                  button
                                  disableTouchRipple
                                  className={styles.groupListItem}
                                >
                                  {!open[group] ? (
                                    <ExpandLess
                                      onClick={() => handleClick(group)}
                                      className={styles.expandIcon}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(group)}
                                      className={styles.expandIcon}
                                    />
                                  )}
                                  <Checkbox
                                    className={cx(styles.checkbox)}
                                    classes={{ checked: styles.checkedBox }}
                                    name={group}
                                    onChange={e => handleSelectAllGroupDevices(e, systems)}
                                    id={group}
                                    value={group}
                                    checked={groupSelected.includes(group)}
                                  />
                                  <ListItemText
                                    primary={group}
                                    className={styles.listItemText}
                                  />
                                </ListItem>
                              </div>
                            )}
                            {group === 'undefined' && (
                              <div>
                                {_.map(systemsType, function (systems, type) {
                                  return (
                                    <div className={styles.typeRowWrapper}>
                                      <ListItem
                                        button
                                        disableTouchRipple
                                        className={styles.typeListItem}
                                      >
                                        {!open[type] ? (
                                          <ExpandLess
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        ) : (
                                          <ExpandMore
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        )}
                                        <ListItemText
                                          primary={type}
                                          className={styles.micaTypeItemText}
                                        />
                                      </ListItem>
                                      <Collapse
                                        in={!open[type]}
                                        timeout='auto'
                                        unmountOnExit
                                      >
                                        {systems.map((system, index) => {
                                          return (
                                            <div
                                              key={system._id}
                                              className={styles.micaItem}
                                            >
                                              <FormControlLabel
                                                className={styles.checkboxForm}
                                                control={
                                                  <Checkbox
                                                    className={cx(styles.checkbox)}
                                                    classes={{
                                                      checked: styles.checkedBox,
                                                      disabled: styles.disabledBox
                                                    }}
                                                    checked={selectedDevices.includes(system._id)}
                                                    onChange={handleChangeDevice}
                                                    required
                                                    outline='true'
                                                    inputRef={register()}
                                                    disableRipple
                                                    disabled={
                                                      selectedDevices.length > 49 &&
                                                      !selectedDevices.includes(system._id)
                                                    }
                                                    name={`selectedDevices[]`}
                                                    value={system._id}
                                                  />
                                                }
                                                label={system.name}
                                              />
                                            </div>
                                          )
                                        })}
                                      </Collapse>
                                    </div>
                                  )
                                })}
                              </div>
                            )}

                            {group !== 'undefined' && (
                              <Collapse
                                in={!open[group]}
                                timeout='auto'
                                unmountOnExit
                                classes={{
                                  wrapper: classes.wrapper
                                }}
                              >
                                {_.map(systemsType, function (systems, type) {
                                  return (
                                    <div className={styles.typeRowWrapper}>
                                      <ListItem
                                        button
                                        disableTouchRipple
                                        className={styles.typeListItem}
                                      >
                                        {!open[type] ? (
                                          <ExpandLess
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        ) : (
                                          <ExpandMore
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        )}
                                        <ListItemText
                                          primary={translate(type)}
                                          className={styles.micaTypeItemText}
                                        />
                                      </ListItem>
                                      <Collapse
                                        in={!open[type]}
                                        timeout='auto'
                                        unmountOnExit
                                      >
                                        {systems.map((system, index) => {
                                          return (
                                            <div
                                              key={index + 'systemFormControlLabelCheckbox'}
                                              className={styles.micaItem}
                                            >
                                              <FormControlLabel
                                                className={styles.checkboxForm}
                                                control={
                                                  <Checkbox
                                                    className={cx(styles.checkbox)}
                                                    classes={{
                                                      checked: styles.checkedBox,
                                                      disabled: styles.disabledBox
                                                    }}
                                                    checked={selectedDevices.includes(system._id)}
                                                    onChange={handleChangeDevice}
                                                    required
                                                    outline='true'
                                                    inputRef={register()}
                                                    disableRipple
                                                    disabled={
                                                      selectedDevices.length > 49 &&
                                                      !selectedDevices.includes(system._id)
                                                    }
                                                    name={`selectedDevices[]`}
                                                    value={system._id}
                                                  />
                                                }
                                                label={system.name}
                                              />
                                            </div>
                                          )
                                        })}
                                      </Collapse>
                                    </div>
                                  )
                                })}
                              </Collapse>
                            )}
                          </div>
                        )
                      })}
                    </List>
                  </Paper>

                  <p className={styles.deviceNumber}>
                    <span className={styles.hover_visible}>
                      <img
                        src='../images/info.svg'
                        className={cx('img_info', styles.infoIcon)}
                        alt=''
                      />
                      <div className={styles.information_box}>
                        <div className={styles.information}>
                          <h4 className={styles.indicatorInformationTitle}>
                            {translate('reportCalibrationModal.selectedDevice')}
                          </h4>
                          <p className='mt-2'>{translate('reportCalibrationModal.deviceNumber')}</p>
                        </div>
                      </div>
                    </span>
                    {translate('selectedDevices') + selectedDevices.length}
                  </p>
                  {errors.selectedDevices && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyDeviceRequired')}</small>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    onClick={onClickNext}
                  >
                    {translate('addReportModal.next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('addReportModal.cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderParams}>
                    {translate('reportCalibrationModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderDevicesSelected}>
                    {translate('reportCalibrationModal.navDownload')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  inputRef={register({ required: true })}
                  fullWidth
                  id='reportName'
                  label={translate('addReportModal.reportName')}
                  onKeyPress={e => {
                    const validCharacters = /^[a-zA-Z0-9\s\b\r\n]+$/
                    if (!validCharacters.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  value={reportNameField}
                  onChange={handleReportNameChange}
                  name='reportName'
                  autoComplete='reportName'
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.reportName
                  })}
                />
                {errors.reportName && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <div className={styles.subInfo}>
                  <Typography>{translate('reportCalibrationModal.reportType')}</Typography>
                </div>
                <div
                  key='regularReport'
                  className={styles.radioFormDiv}
                >
                  <FormControlLabel
                    className={styles.checkboxForm}
                    control={
                      <Radio
                        className={styles.checkbox}
                        onChange={handleChangeLevelTypes}
                        checked={reportType === 'regularReport'}
                        value='regularReport'
                        id='regularReport'
                        classes={{ checked: styles.checkedBox }}
                        required
                        name='regularReport'
                        inputRef={register()}
                        disableRipple
                      />
                    }
                    label={translate('reportCalibrationModal.regularReport')}
                  />
                  <span className={styles.hover_visible}>
                    <img
                      src='../images/info.svg'
                      className={cx('img_info', styles.infoIcon)}
                      alt=''
                    />
                    <div className={styles.information_box}>
                      <div className={styles.information}>
                        <h4 className={styles.indicatorInformationTitle}>
                          {translate('reportCalibrationModal.regularCalibration')}
                        </h4>
                        <p className='mt-2'>
                          {translate('reportCalibrationModal.regularCalibrationText')}
                        </p>
                      </div>
                    </div>
                  </span>
                </div>
                <div
                  key='wellReport'
                  className={styles.radioFormDiv}
                >
                  <FormControlLabel
                    className={styles.checkboxForm}
                    control={
                      <Radio
                        className={styles.checkbox}
                        onChange={handleChangeLevelTypes}
                        checked={reportType === 'wellReport'}
                        value='wellReport'
                        id='wellReport'
                        classes={{ checked: styles.checkedBox }}
                        required
                        name='wellReport'
                        inputRef={register()}
                        disableRipple
                      />
                    }
                    label={translate('reportCalibrationModal.wellReport')}
                  />

                  <span className={styles.hover_visible}>
                    <img
                      src='../images/info.svg'
                      className={cx('img_info', styles.infoIcon)}
                      alt=''
                    />
                    <div className={styles.information_box}>
                      <div className={styles.information}>
                        <h4 className={styles.indicatorInformationTitle}>
                          {translate('reportCalibrationModal.wellCalibration')}
                        </h4>
                        <p className='mt-2'>
                          {translate('reportCalibrationModal.wellCalibrationText')}
                        </p>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div className={styles.buttonWrapper}>
                <LoadingButton
                  type='submit'
                  fullWidth
                  loading={isSubmitting}
                  loadingPosition='end'
                  variant='contained'
                  className={styles.buttonSiguiente}
                  onClick={onClickDownload}
                >
                  {translate('addReportModal.downloadReport')}
                </LoadingButton>
              </div>
              <div className={styles.buttonWrapper}>
                <Button
                  fullWidth
                  variant='contained'
                  className={styles.buttonCancelar}
                  onClick={onClickBack}
                  disabled={isSubmitting}
                >
                  <ArrowBack className={styles.arrow} />
                  {translate('addReportModal.back')}
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

export default withLocalize(ReportCalibrationModalForm)
