import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { withLocalize } from 'react-localize-redux'
import cx from 'classnames'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

import DateFnsUtils from '@date-io/date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import localeSpanish from 'date-fns/locale/es'
import localeEnglish from 'date-fns/locale/en-US'
import { ArrowForward, ArrowBack } from '@material-ui/icons'
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined'
import { StylesProvider, makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {
  createMuiTheme,
  TextField,
  Typography,
  Button,
  InputAdornment,
  ListItem,
  FormControlLabel,
  Checkbox,
  Collapse,
  ListItemText,
  Switch,
  Radio
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { LoadingButton } from '@mui/lab'
import { ThemeProvider } from '@material-ui/styles'

import * as SystemUtils from 'utils/system'
import styles from './AddReportModalForm.module.scss'
import DataTypes from 'constants/DataTypes'

const useStyles = makeStyles(() => ({
  input: {
    [`& fieldset`]: {
      borderRadius: 30,
      borderColor: 'grey'
    },
    '& label.Mui-focused': {
      color: blue[600]
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  inp: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'grey'
      },
      '&.Mui-focused fieldset': {
        borderColor: blue[600]
      }
    }
  },
  switch: {
    '& .Mui-checked': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary': {
      color: 'rgba(255,255,255)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: 'rgba(0,155,229,1)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: blue[600],
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-thumb': {
      color: blue[600]
    },
    '& .MuiSwitch-colorSecondary.Mui-checked:hover': {
      backgroundColor: blue[100]
    },
    '& .MuiSwitch-thumb': {
      transform: 'translateY(-1px)',
      width: '21px',
      height: '21px'
    },
    '& .MuiSwitch-track': {
      backgroundColor: 'rgba(33,33,33, 0.4)'
    }
  },
  paper: {
    border: 'none',
    boxShadow: 'none'
  },
  wrapper: {
    width: '100%'
  },
  MuiTimePicker: {
    styleOverrides: {
      root: {
        backgroundColor: blue[600]
      }
    }
  }
}))
const customTheme = createMuiTheme({
  overrides: {
    MuiPickersDay: {
      dayDisabled: {
        textDecoration: 'line-through'
      }
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          borderColor: 'transparent',
          outline: 'none'
        },
        '&:focus': {
          borderColor: 'transparent',
          outline: 'none'
        }
      }
    }
  }
})

const AddReportModalForm = props => {
  const { onSubmit, translate, systems, systemsByGroup, activeLanguage, error } = props
  const { formState, handleSubmit, register, errors, getValues, setError, clearErrors } = useForm()
  const { isSubmitting } = formState
  const classes = useStyles()
  const [location, setLocation] = useState()
  const [reportNameField, setReportNameField] = useState()
  const [selectedDevices, setSelectedDevices] = useState([])
  const [endDateState, setEndDateState] = useState(new Date())
  const [systemsGroupState, setSystemsGroupState] = useState(systemsByGroup)
  let keys = Object.keys(systemsGroupState)
  keys = keys.filter(function (element) {
    return element !== 'undefined'
  })
  const [typeChecked, setTypeChecked] = useState([])
  const [typeCheckedBox, setTypeCheckedBox] = useState([])
  const [open, setOpen] = useState({})
  const [type, setType] = useState([])
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheckAllDay, setIsCheckAllDay] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const defaultDate = new Date()
  defaultDate.setHours(0)
  defaultDate.setMinutes(0)
  const [selectedDate, setSelectedDate] = useState(defaultDate)
  const [startSelectedDate, setStartSelectedDate] = useState(defaultDate)
  const [selectedDate1, setSelectedDate1] = useState(defaultDate)
  const [startSelectedDate1, setStartSelectedDate1] = useState(defaultDate)
  const [dataState, setDataState] = useState({})
  const [secondOccupationHoursChecked, setSecondOccupationHoursChecked] = useState(false)
  const [isCheckAllDevices, setIsCheckAllDevices] = useState(false)
  const [checkAllDevicesIsClicked, setCheckDevicesAllIsClicked] = useState(false)
  const [checkAllIsClicked, setCheckAllIsClicked] = useState(false)
  const [optionValue, setOptionValue] = useState('auto')
  const [micasName, setMicasName] = useState()
  const [micasType, setMicasType] = useState()
  const [groupSelected, setGroupSelected] = useState([])
  const [openPicker, setOpenPicker] = useState(false)
  const [isActive, setIsActive] = useState(0)
  const [period1, setPeriod1] = useState([])
  const [period2, setPeriod2] = useState([])
  const [auxPeriod, setAuxPeriod] = useState([])
  const [auxPeriod2, setAuxPeriod2] = useState([])
  const [auxDays, setAuxDays] = useState()

  const now = new Date()

  let offset = now.getTimezoneOffset()
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  useEffect(() => {
    clearErrors('fourDates')
    clearErrors('noChecked')
    setPeriod1([])
    setPeriod2([])
    setAuxPeriod([])
    setAuxPeriod2([])
    const firstUtcStartHour = new Date(startSelectedDate.getTime() + offset * 60000)
    const firstUtcEndHour = new Date(selectedDate.getTime() + offset * 60000)
    const secondUtcStartHour = new Date(startSelectedDate1.getTime() + offset * 60000)
    const secondUtcEndHour = new Date(selectedDate1.getTime() + offset * 60000)
    if (offset < 0) {
      if (startSelectedDate.getDate() === firstUtcStartHour.getDate()) {
        setPeriod1([
          firstUtcStartHour.getHours() +
            ':' +
            firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
          firstUtcEndHour.getHours() +
            ':' +
            firstUtcEndHour.getMinutes().toString().padStart(2, '0')
        ])

        setPeriod2([
          secondUtcStartHour.getHours() +
            ':' +
            secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
          secondUtcEndHour.getHours() +
            ':' +
            secondUtcEndHour.getMinutes().toString().padStart(2, '0')
        ])
      } else {
        if (selectedDate.getDate() === firstUtcEndHour.getDate()) {
          setAuxPeriod([
            firstUtcStartHour.getHours() +
              ':' +
              firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
            '23:59'
          ])
          setPeriod1([
            '00:00',
            firstUtcEndHour.getHours() +
              ':' +
              firstUtcEndHour.getMinutes().toString().padStart(2, '0')
          ])
          setPeriod2([
            secondUtcStartHour.getHours() +
              ':' +
              secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
            secondUtcEndHour.getHours() +
              ':' +
              secondUtcEndHour.getMinutes().toString().padStart(2, '0')
          ])
          let auxDaysTable = {}
          for (let i = 1; i < days.length; i++) {
            let day = days[i]
            if (typeCheckedBox[day]) {
              let dayss = days[i - 1]
              auxDaysTable[dayss] = true
            }
          }
          if (typeCheckedBox['Monday']) {
            auxDaysTable['Sunday'] = true
          }
          setAuxDays(auxDaysTable)
        } else {
          if (secondOccupationHoursChecked) {
            if (secondUtcStartHour.getDate() === startSelectedDate1.getDate()) {
              setAuxPeriod([
                firstUtcStartHour.getHours() +
                  ':' +
                  firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
                firstUtcEndHour.getHours() +
                  ':' +
                  firstUtcEndHour.getMinutes().toString().padStart(2, '0')
              ])
              setPeriod1(['00:00', '00:00'])
              setPeriod2([
                secondUtcStartHour.getHours() +
                  ':' +
                  secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
                secondUtcEndHour.getHours() +
                  ':' +
                  secondUtcEndHour.getMinutes().toString().padStart(2, '0')
              ])
              let auxDaysTable = {}
              for (let i = 1; i < days.length; i++) {
                let day = days[i]
                if (typeCheckedBox[day]) {
                  let dayss = days[i - 1]
                  auxDaysTable[dayss] = true
                }
              }
              if (typeCheckedBox['Monday']) {
                auxDaysTable['Sunday'] = true
              }
              setAuxDays(auxDaysTable)
            } else {
              if (secondUtcEndHour.getDate() === selectedDate1.getDate()) {
                setAuxPeriod([
                  firstUtcStartHour.getHours() +
                    ':' +
                    firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  firstUtcEndHour.getHours() +
                    ':' +
                    firstUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setPeriod1(['00:00', '00:00'])
                setPeriod2([
                  '00:00',
                  secondUtcEndHour.getHours() +
                    ':' +
                    secondUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setAuxPeriod2([
                  secondUtcStartHour.getHours() +
                    ':' +
                    secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  '23:59'
                ])
                let auxDaysTable = {}
                for (let i = 1; i < days.length; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i - 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Monday']) {
                  auxDaysTable['Sunday'] = true
                }
                setAuxDays(auxDaysTable)
              } else {
                setAuxPeriod([
                  firstUtcStartHour.getHours() +
                    ':' +
                    firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  firstUtcEndHour.getHours() +
                    ':' +
                    firstUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setPeriod1(['00:00', '00:00'])
                setPeriod2(['00:00', '00:00'])
                setAuxPeriod2([
                  secondUtcStartHour.getHours() +
                    ':' +
                    secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  secondUtcEndHour.getHours() +
                    ':' +
                    secondUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                let auxDaysTable = {}
                for (let i = 1; i < days.length; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i - 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Monday']) {
                  auxDaysTable['Sunday'] = true
                }
                setAuxDays(auxDaysTable)
              }
            }
          } else {
            setAuxPeriod([
              firstUtcStartHour.getHours() +
                ':' +
                firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
              firstUtcEndHour.getHours() +
                ':' +
                firstUtcEndHour.getMinutes().toString().padStart(2, '0')
            ])
            setPeriod1(['00:00', '00:00'])
            let auxDaysTable = {}
            for (let i = 1; i < days.length; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i - 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Monday']) {
              auxDaysTable['Sunday'] = true
            }
            setAuxDays(auxDaysTable)
          }
        }
      }
    } else {
      if (secondOccupationHoursChecked) {
        if (selectedDate1.getDate() === secondUtcEndHour.getDate()) {
          setPeriod2([
            secondUtcStartHour.getHours() +
              ':' +
              secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
            secondUtcEndHour.getHours() +
              ':' +
              secondUtcEndHour.getMinutes().toString().padStart(2, '0')
          ])
          setPeriod1([
            firstUtcStartHour.getHours() +
              ':' +
              firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
            firstUtcEndHour.getHours() +
              ':' +
              firstUtcEndHour.getMinutes().toString().padStart(2, '0')
          ])
        } else {
          if (startSelectedDate1.getDate() === secondUtcStartHour.getDate()) {
            let auxDaysTable = {}
            for (let i = 0; i < days.length - 1; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i + 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Sunday']) {
              auxDaysTable['Monday'] = true
            }

            setAuxPeriod2([
              '00:00',
              secondUtcEndHour.getHours() +
                ':' +
                secondUtcEndHour.getMinutes().toString().padStart(2, '0')
            ])
            setPeriod1([
              firstUtcStartHour.getHours() +
                ':' +
                firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
              firstUtcEndHour.getHours() +
                ':' +
                firstUtcEndHour.getMinutes().toString().padStart(2, '0')
            ])
            setPeriod2([
              secondUtcStartHour.getHours() +
                ':' +
                secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
              '23:59'
            ])
            setAuxDays(auxDaysTable)
          } else {
            if (selectedDate.getDate() === firstUtcEndHour.getDate()) {
              let auxDaysTable = {}
              for (let i = 0; i < days.length - 1; i++) {
                let day = days[i]
                if (typeCheckedBox[day]) {
                  let dayss = days[i + 1]
                  auxDaysTable[dayss] = true
                }
              }
              if (typeCheckedBox['Sunday']) {
                auxDaysTable['Monday'] = true
              }

              setAuxPeriod2([
                secondUtcStartHour.getHours() +
                  ':' +
                  secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
                secondUtcEndHour.getHours() +
                  ':' +
                  secondUtcEndHour.getMinutes().toString().padStart(2, '0')
              ])
              setPeriod1([
                firstUtcStartHour.getHours() +
                  ':' +
                  firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
                firstUtcEndHour.getHours() +
                  ':' +
                  firstUtcEndHour.getMinutes().toString().padStart(2, '0')
              ])
              setPeriod2(['00:00', '00:00'])

              setAuxDays(auxDaysTable)
            } else {
              if (firstUtcStartHour.getDate() === startSelectedDate.getDate()) {
                let auxDaysTable = {}
                for (let i = 0; i < days.length - 1; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i + 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Sunday']) {
                  auxDaysTable['Monday'] = true
                }

                setAuxPeriod2([
                  secondUtcStartHour.getHours() +
                    ':' +
                    secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  secondUtcEndHour.getHours() +
                    ':' +
                    secondUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setPeriod1([
                  firstUtcStartHour.getHours() +
                    ':' +
                    firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  '23:59'
                ])
                setPeriod2(['00:00', '00:00'])
                setAuxPeriod([
                  '00:00',
                  firstUtcEndHour.getHours() +
                    ':' +
                    firstUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setAuxDays(auxDaysTable)
              } else {
                let auxDaysTable = {}
                for (let i = 0; i < days.length - 1; i++) {
                  let day = days[i]
                  if (typeCheckedBox[day]) {
                    let dayss = days[i + 1]
                    auxDaysTable[dayss] = true
                  }
                }
                if (typeCheckedBox['Sunday']) {
                  auxDaysTable['Monday'] = true
                }

                setAuxPeriod2([
                  secondUtcStartHour.getHours() +
                    ':' +
                    secondUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  secondUtcEndHour.getHours() +
                    ':' +
                    secondUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setPeriod1(['00:00', '00:00'])
                setPeriod2(['00:00', '00:00'])
                setAuxPeriod([
                  firstUtcStartHour.getHours() +
                    ':' +
                    firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
                  firstUtcEndHour.getHours() +
                    ':' +
                    firstUtcEndHour.getMinutes().toString().padStart(2, '0')
                ])
                setAuxDays(auxDaysTable)
              }
            }
          }
        }
      } else {
        if (selectedDate.getDate() === firstUtcEndHour.getDate()) {
          setPeriod1([
            firstUtcStartHour.getHours() +
              ':' +
              firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
            firstUtcEndHour.getHours() +
              ':' +
              firstUtcEndHour.getMinutes().toString().padStart(2, '0')
          ])
        } else {
          if (startSelectedDate.getDate() === firstUtcStartHour.getDate()) {
            setAuxPeriod([
              '00:00',
              firstUtcEndHour.getHours() +
                ':' +
                firstUtcEndHour.getMinutes().toString().padStart(2, '0')
            ])
            setPeriod1([
              firstUtcStartHour.getHours() +
                ':' +
                firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
              '23:59'
            ])
            let auxDaysTable = {}
            for (let i = 1; i < days.length; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i - 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Sunday']) {
              auxDaysTable['Monday'] = true
            }
            setAuxDays(auxDaysTable)
          } else {
            setAuxPeriod([
              firstUtcStartHour.getHours() +
                ':' +
                firstUtcStartHour.getMinutes().toString().padStart(2, '0'),
              firstUtcEndHour.getHours() +
                ':' +
                firstUtcEndHour.getMinutes().toString().padStart(2, '0')
            ])
            setPeriod1(['00:00', '00:00'])
            let auxDaysTable = {}
            for (let i = 1; i < days.length; i++) {
              let day = days[i]
              if (typeCheckedBox[day]) {
                let dayss = days[i - 1]
                auxDaysTable[dayss] = true
              }
            }
            if (typeCheckedBox['Sunday']) {
              auxDaysTable['Monday'] = true
            }
            setAuxDays(auxDaysTable)
          }
        }
      }
    }
  }, [startSelectedDate1, selectedDate1, startSelectedDate, selectedDate, typeCheckedBox])

  useEffect(() => {
    let filter = systems.filter(s => selectedDevices.includes(s._id))
    let micasIdName = {}
    let micasNameType = {}
    for (const element of filter) {
      micasIdName[element._id] = element.name
      micasNameType[element.name] = element.micaType
    }
    setMicasName(micasIdName)
    setMicasType(micasNameType)
  }, [selectedDevices])

  const subtractDays = (n, date) => {
    date.setTime(date.getTime() - n * 24 * 3600 * 1000)
    return date
  }
  const [startDate, setStarDate] = useState(subtractDays(7, new Date()))

  const endDate = new Date()
  const handleChangeOption = event => {
    setOptionValue(event.target.value)
  }
  const isIndeterminate = () => {
    return checkAllIsClicked && typeChecked.length > 0 && typeChecked.length !== type.length
  }
  let locale
  if (activeLanguage.code === 'es') locale = localeSpanish
  if (activeLanguage.code === 'en') locale = localeEnglish

  const handleReportNameChange = event => {
    setReportNameField(event.target.value)
    if (event.target.value !== '') {
      clearErrors('reportName')
    }
  }
  const handleSelectAllDevices = event => {
    setIsCheckAllDevices(!isCheckAllDevices)
    setCheckDevicesAllIsClicked(true)
    if (isCheckAllDevices) {
      setGroupSelected([])
      setSelectedDevices([])
    } else {
      setGroupSelected(keys)
      const systemIds = []
      systemOrdered.slice(0, 40).map(s => {
        systemIds.push(s._id)
        return null
      })
      setSelectedDevices(systemIds)
      clearErrors('selectedDevices')
    }
  }
  const isIndeterminateDevices = () => {
    return (
      checkAllDevicesIsClicked &&
      selectedDevices.length > 0 &&
      selectedDevices.length !== systems.length
    )
  }

  const handleChange = event => {
    if (event.target.type === 'checkbox') {
      const { value, checked } = event.target
      setTypeChecked([...typeChecked, value])
      if (!checked) {
        setTypeChecked(typeChecked.filter(item => item !== value))
      }
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams === 0) {
        setError('types', { shouldFocus: 'types' })
      } else {
        clearErrors('types')
      }
    }
  }

  const handleChangeDevice = event => {
    const { checked, value } = event.target
    if (checked) {
      setSelectedDevices([...selectedDevices, value])
    } else {
      setSelectedDevices(selectedDevices.filter(item => item !== value))
    }
  }

  let typeArray = []
  const data = {}
  type.map((t, i) => {
    if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0 && t !== 'weather')
      typeArray.push(t)
    if (t === 'weather') data.weather = getValues(`types[${i}]`).length > 0
    return null
  })
  const getMinDate = date => {
    const minDate = new Date(date)
    if (typeChecked?.length > 8 && selectedDevices.length > 35) {
      minDate.setDate(minDate.getDate() - 31)
    } else if (typeChecked?.length > 8 && selectedDevices.length <= 35) {
      minDate.setDate(minDate.getDate() - 61)
    } else if (typeChecked?.length > 4) {
      minDate.setDate(minDate.getDate() - 75)
    } else {
      minDate.setDate(minDate.getDate() - 121)
    }
    return minDate
  }

  const shouldDisableDate = date => {
    const minDate = getMinDate(endDateState)
    return date < minDate
  }

  const getMaxDate = date => {
    const maxDate = new Date(date)
    if (typeChecked?.length > 8 && selectedDevices.length > 35) {
      maxDate.setDate(maxDate.getDate() + 30)
    } else if (typeChecked?.length > 8 && selectedDevices.length <= 35) {
      maxDate.setDate(maxDate.getDate() + 60)
    } else if (typeChecked?.length > 4) {
      maxDate.setDate(maxDate.getDate() + 74)
    } else {
      maxDate.setDate(maxDate.getDate() + 120)
    }
    return maxDate
  }

  const shouldDisableEndDate = date => {
    const minDate = getMaxDate(startDate)
    return date > minDate
  }

  const selectedWeeks = () => {
    let weekNumbers = ''
    if (typeChecked?.length > 8 && selectedDevices.length > 35) {
      weekNumbers = 4
    } else if (typeChecked?.length > 8 && selectedDevices.length <= 35) {
      weekNumbers = 8
    } else if (typeChecked?.length > 4) {
      weekNumbers = 10
    } else {
      weekNumbers = 16
    }
    return weekNumbers
  }

  const handleSelectAllGroupDevices = (event, systems) => {
    const { checked, value } = event.target
    if (checked) {
      setGroupSelected([...groupSelected, value])
    } else {
      setGroupSelected(groupSelected.filter(item => item !== value))
    }
    if (event.target.checked) {
      const aux = []
      let j = selectedDevices.length
      for (let i = 0; i < systems.length; i++) {
        if (!selectedDevices.includes(systems[i]._id)) {
          if (j < 40) {
            aux.push(systems[i]._id)
            j++
          }
        }
      }
      setSelectedDevices(selectedDevices => [...selectedDevices, ...aux])
      clearErrors('selectedDevices')
    } else {
      const systemIds = []
      for (let i = 0; i < systems.length; i++) {
        systemIds.push(systems[i]._id)
      }
      setSelectedDevices(selectedDevices.filter(s => !systemIds.includes(s)))
    }
  }
  const handleChangeCheckbox = event => {
    clearErrors('noChecked')
    setTypeCheckedBox({ ...typeCheckedBox, [event.target.value]: event.target.checked })
  }

  const handleChangeSecondOccupationHoursChecked = event => {
    setSecondOccupationHoursChecked(event.target.checked)
  }

  const renderError = () => {
    if (error === '') {
      return null
    }
    let message
    if (error === 'statusCode:409') {
      message = translate('submitErrors.Nodata')
    } else {
      message = translate('submitErrors.NoSensors')
    }
    return (
      <Alert
        severity='error'
        className={styles.errorMessage}
      >
        {message}
      </Alert>
    )
  }

  const handleSelectAll = event => {
    setIsCheckAll(!isCheckAll)
    setTypeChecked(type)
    setCheckAllIsClicked(true)
    if (isCheckAll) {
      setTypeChecked([])
    } else {
      clearErrors('types')
    }
  }

  const handleSelectAllDays = event => {
    setIsCheckAllDay(!isCheckAllDay)
    const sTypes = {}
    days.forEach(t => {
      sTypes[t] = true
    })
    setTypeCheckedBox(sTypes)
    if (isCheckAllDay) {
      setTypeCheckedBox({})
    } else {
      clearErrors('noChecked')
    }
  }

  const handleLocationChange = event => {
    setLocation(event.target.value)
    if (event.target.value !== '') {
      clearErrors('location')
    }
  }

  const handleClickPicker = number => {
    setOpenPicker(true)
    setIsActive(number)
  }
  const handleClose = () => {
    setOpenPicker(false)
  }
  const handleClick = key => {
    const openKey = open[key]
    setOpen({ ...open, [key]: !openKey })
  }

  const onRangeChange = e => {
    let startDates
    switch (e.target.value) {
      case '2M':
        startDates = subtractDays(60, new Date(endDate))
        break
      case '1w':
        startDates = subtractDays(7, new Date(endDate))
        break
      case '2w':
        startDates = subtractDays(14, new Date(endDate))
        break
      case '1M':
        startDates = subtractDays(30, new Date(endDate))
        break
    }
    setStarDate(startDates)
  }

  const onClickNext = () => {
    let selected = getValues()
    if (selected.selectedDevices !== undefined && !Array.isArray(selected.selectedDevices)) {
      selected.selectedDevices = [selected.selectedDevices]
    }
    if (activeTab === 0) {
      if (
        getValues('reportName') !== '' &&
        selected &&
        selected.selectedDevices !== undefined &&
        selected.selectedDevices.length > 0 &&
        getValues('location') !== ''
      ) {
        const types = []
        for (let i = 0; i < selected.selectedDevices.length; i++) {
          const system = systems.filter(s => {
            return s._id === selected.selectedDevices[i]
          })[0]
          const sortedData = SystemUtils.getDataSorted(system)
          sortedData.map(d => {
            if (types.indexOf(d.type) === -1) {
              types.push(d.type)
            }
            return null
          })
        }
        if (types.indexOf(DataTypes.THERMAL_INDICATOR) !== -1)
          types.splice(types.indexOf(DataTypes.THERMAL_INDICATOR), 1)
        if (types.indexOf(DataTypes.VENTILATION_INDICATOR) !== -1)
          types.splice(types.indexOf(DataTypes.VENTILATION_INDICATOR), 1)
        if (types.indexOf(DataTypes.IAQ) !== -1) types.splice(types.indexOf(DataTypes.IAQ), 1)
        if (types.indexOf(DataTypes.COVID_19) !== -1)
          types.splice(types.indexOf(DataTypes.COVID_19), 1)
        if (types.indexOf(DataTypes.NOX) !== -1) types.splice(types.indexOf(DataTypes.NOX), 1)
        setType(types)

        setActiveTab(1)
      }
      if (getValues('reportName') === '') {
        setError('reportName', { shouldFocus: 'reportName' })
      }

      if (selected.selectedDevices === undefined || selected.selectedDevices.length === 0) {
        setError('selectedDevices', { shouldFocus: 'selectedDevices' })
      }
      if (getValues('location') === '') {
        setError('location', { shouldFocus: 'location' })
      }
    } else if (activeTab === 1) {
      let numberOfParams = 0
      type.map((t, i) => {
        if (getValues(`types[${i}]`) && getValues(`types[${i}]`).length > 0) {
          // Hay seleccionados
          numberOfParams++
        }
        return null
      })
      if (numberOfParams !== 0) {
        setActiveTab(2)
      } else {
        setError('types', { shouldFocus: 'types' })
      }
    } else {
      if (moment(endDateState) < moment(startDate)) {
        setError('rangeDate', { shouldFocus: 'rangeDate' })
      } else {
        clearErrors('rangeDate')
        setActiveTab(3)
      }
    }
  }

  const onClickDownload = () => {
    downloadReport()
  }

  const onClickBack = () => {
    if (activeTab === 3) {
      setActiveTab(2)
      clearErrors('types')
    } else if (activeTab === 2) {
      setActiveTab(1)
    } else {
      setActiveTab(0)
    }
  }

  const onClickCancel = () => {
    props.hideModal()
  }

  const daysTranslated = [
    translate('addReportModal.monday'),
    translate('addReportModal.tuesday'),
    translate('addReportModal.wednesday'),
    translate('addReportModal.thursday'),
    translate('addReportModal.friday'),
    translate('addReportModal.saturday'),
    translate('addReportModal.sunday')
  ]

  const downloadReport = () => {
    const auxTypeSelected = []
    days.forEach(t => {
      if (typeCheckedBox[t]) {
        auxTypeSelected.push(t)
      }
    })
    if (auxTypeSelected.length !== 0) {
      clearErrors('noChecked')
    } else {
      setError('noChecked', { shouldFocus: 'noChecked' })
    }
    if (startSelectedDate.getHours() > selectedDate.getHours()) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      startSelectedDate.getHours() === selectedDate.getHours() &&
      startSelectedDate.getMinutes().toString().padStart(2, '0') >=
        selectedDate.getMinutes().toString().padStart(2, '0')
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      startSelectedDate1.getHours() > selectedDate1.getHours()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      startSelectedDate1.getHours() === selectedDate1.getHours() &&
      startSelectedDate1.getMinutes().toString().padStart(2, '0') >=
        selectedDate1.getMinutes().toString().padStart(2, '0')
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      selectedDate1.getHours() <= startSelectedDate.getHours()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      startSelectedDate1.getHours() < selectedDate.getHours()
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else if (
      secondOccupationHoursChecked &&
      startSelectedDate1.getHours() === selectedDate.getHours() &&
      startSelectedDate1.getMinutes().toString().padStart(2, '0') <=
        selectedDate.getMinutes().toString().padStart(2, '0')
    ) {
      setError('fourDates', { shouldFocus: 'fourDates' })
    } else {
      setStartSelectedDate(startSelectedDate)
      setSelectedDate(selectedDate)
      setStartSelectedDate1(startSelectedDate1)
      setSelectedDate1(selectedDate1)
      clearErrors('fourDates')
    }

    const data = {}
    data.deviceId = selectedDevices
    data.name = getValues('reportName')
    data.location = getValues('location')
    data.startDate =
      optionValue === 'auto'
        ? new Date(startDate.setHours(0, 0, 0))
        : new Date(startDate.setHours(0, 0, 0))
    data.endDate =
      optionValue === 'auto'
        ? new Date(endDate.setHours(23, 59, 0))
        : new Date(endDateState.setHours(23, 59, 0))
    data.firstOcupationStart = startSelectedDate
    data.firstOcupationEnd = selectedDate
    if (secondOccupationHoursChecked) data.secondOcupationStart = startSelectedDate1
    if (secondOccupationHoursChecked) data.secondOcupationEnd = selectedDate1
    data.occupationDays = typeCheckedBox
    data.params = typeArray
    data.micasName = micasName
    data.micasType = micasType
    data.period1 = period1
    if (secondOccupationHoursChecked) {
      data.period2 = period2
    }
    if (auxPeriod.length > 0) {
      data.auxPeriod = auxPeriod
      data.auxDays = auxDays
    }
    if (auxPeriod2.length > 0) {
      data.auxPeriod2 = auxPeriod2
    }
    setDataState(data)
  }

  const filterExpressionChange = event => {
    let systemsJS = []
    systemsJS = systems.filter(s => {
      return s.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1
    })
    setSystemsGroupState(_.groupBy(systemsJS, 'group'))
  }
  const isIndeterminateDay = () => {
    let isIndet = false
    if (isCheckAllDay) {
      days.forEach(t => {
        if (!setTypeChecked[t]) {
          isIndet = true
        }
      })
    }
    return isIndet
  }
  const systemOrdered = []

  _.map(systemsGroupState, function (systems, group) {
    const systemsType = _.groupBy(systems, 'micaType')

    group === 'undefined' &&
      _.map(systemsType, function (systems, type) {
        systems.map((system, index) => {
          systemOrdered.push(system)
        })
      })
    group !== 'undefined' &&
      _.map(systemsType, function (systems, type) {
        systems.map((system, index) => {
          systemOrdered.push(system)
        })
      })
  })

  return (
    <StylesProvider injectFirst>
      <div className={styles.modalContainer}>
        <Typography className={styles.header}>{translate('addReportModal.newReport')}</Typography>
        <div className={styles.modal}>
          <form
            noValidate
            onSubmit={handleSubmit(() => onSubmit(dataState))}
          >
            <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
              <div className={styles.firstPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addReportModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navInterval')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navOccupation')}
                  </Typography>
                </div>
                <TextField
                  variant='outlined'
                  required
                  inputRef={register({ required: true })}
                  fullWidth
                  id='reportName'
                  label={translate('addReportModal.reportName')}
                  onKeyPress={e => {
                    const validCharacters = /^[a-zA-Z0-9\s\b\r\n]+$/
                    if (!validCharacters.test(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  value={reportNameField}
                  onChange={handleReportNameChange}
                  name='reportName'
                  autoComplete='reportName'
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.reportName
                  })}
                />
                {errors.reportName && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <TextField
                  variant='outlined'
                  required
                  inputRef={register({ required: true })}
                  fullWidth
                  id='location '
                  label={translate('addReportModal.location')}
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  value={location}
                  onChange={handleLocationChange}
                  name='location'
                  autoComplete='location'
                  className={cx(styles.input, classes.input, {
                    [styles.hasError]: errors.location
                  })}
                />
                {errors.location && (
                  <div className={styles.error}>
                    <small>{translate('validation.required')}</small>
                  </div>
                )}
                <div>
                  <TextField
                    variant='outlined'
                    fullWidth
                    type='search'
                    onChange={filterExpressionChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    id='searchDevice'
                    name='searchDevice'
                    placeholder={translate('searchDevice')}
                    className={cx(styles.input, classes.input, {
                      [styles.hasError]: errors.reportName
                    })}
                  />
                  <FormControlLabel
                    className={styles.selectAllCheckbox}
                    control={
                      <Checkbox
                        className={cx(styles.checkbox)}
                        classes={{ checked: styles.checkedBox }}
                        name='selectAllDevices'
                        id='selectAllDevices'
                        indeterminate={isIndeterminateDevices()}
                        onChange={handleSelectAllDevices}
                        checked={isCheckAllDevices}
                      />
                    }
                    label={translate('selectFifty')}
                  />
                  <Paper
                    className={styles.devicesListPaper}
                    classes={{ root: classes.paper }}
                  >
                    <List
                      disableTouchRipple
                      className={styles.devicesList}
                    >
                      {_.map(systemsGroupState, function (systems, group) {
                        const systemsType = _.groupBy(systems, 'micaType')
                        return (
                          <div className={styles.noGroupWrapper}>
                            {group !== 'undefined' && (
                              <div>
                                <ListItem
                                  button
                                  disableTouchRipple
                                  className={styles.groupListItem}
                                >
                                  {!open[group] ? (
                                    <ExpandLess
                                      onClick={() => handleClick(group)}
                                      className={styles.expandIcon}
                                    />
                                  ) : (
                                    <ExpandMore
                                      onClick={() => handleClick(group)}
                                      className={styles.expandIcon}
                                    />
                                  )}
                                  <Checkbox
                                    className={cx(styles.checkbox)}
                                    classes={{ checked: styles.checkedBox }}
                                    name={group}
                                    onChange={e => handleSelectAllGroupDevices(e, systems)}
                                    id={group}
                                    value={group}
                                    checked={groupSelected.includes(group)}
                                  />
                                  <ListItemText
                                    primary={group}
                                    className={styles.listItemText}
                                  />
                                </ListItem>
                              </div>
                            )}
                            {group === 'undefined' && (
                              <div>
                                {_.map(systemsType, function (systems, type) {
                                  return (
                                    <div className={styles.typeRowWrapper}>
                                      <ListItem
                                        button
                                        disableTouchRipple
                                        className={styles.typeListItem}
                                      >
                                        {!open[type] ? (
                                          <ExpandLess
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        ) : (
                                          <ExpandMore
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        )}
                                        <ListItemText
                                          primary={type}
                                          className={styles.micaTypeItemText}
                                        />
                                      </ListItem>
                                      <Collapse
                                        in={!open[type]}
                                        timeout='auto'
                                        unmountOnExit
                                      >
                                        {systems.map((system, index) => {
                                          return (
                                            <div
                                              key={system._id}
                                              className={styles.micaItem}
                                            >
                                              <FormControlLabel
                                                className={styles.checkboxForm}
                                                control={
                                                  <Checkbox
                                                    className={cx(styles.checkbox)}
                                                    classes={{
                                                      checked: styles.checkedBox,
                                                      disabled: styles.disabledBox
                                                    }}
                                                    checked={selectedDevices.includes(system._id)}
                                                    onChange={handleChangeDevice}
                                                    required
                                                    outline='true'
                                                    inputRef={register()}
                                                    disableRipple
                                                    disabled={
                                                      selectedDevices.length > 39 &&
                                                      !selectedDevices.includes(system._id)
                                                    }
                                                    name={`selectedDevices[]`}
                                                    value={system._id}
                                                  />
                                                }
                                                label={system.name}
                                              />
                                            </div>
                                          )
                                        })}
                                      </Collapse>
                                    </div>
                                  )
                                })}
                              </div>
                            )}

                            {group !== 'undefined' && (
                              <Collapse
                                in={!open[group]}
                                timeout='auto'
                                unmountOnExit
                                classes={{
                                  wrapper: classes.wrapper
                                }}
                              >
                                {_.map(systemsType, function (systems, type) {
                                  return (
                                    <div className={styles.typeRowWrapper}>
                                      <ListItem
                                        button
                                        disableTouchRipple
                                        className={styles.typeListItem}
                                      >
                                        {!open[type] ? (
                                          <ExpandLess
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        ) : (
                                          <ExpandMore
                                            onClick={() => handleClick(type)}
                                            className={styles.expandIcon}
                                          />
                                        )}
                                        <ListItemText
                                          primary={translate(type)}
                                          className={styles.micaTypeItemText}
                                        />
                                      </ListItem>
                                      <Collapse
                                        in={!open[type]}
                                        timeout='auto'
                                        unmountOnExit
                                      >
                                        {systems.map((system, index) => {
                                          return (
                                            <div
                                              key={index + 'systemFormControlLabelCheckbox'}
                                              className={styles.micaItem}
                                            >
                                              <FormControlLabel
                                                className={styles.checkboxForm}
                                                control={
                                                  <Checkbox
                                                    className={cx(styles.checkbox)}
                                                    classes={{
                                                      checked: styles.checkedBox,
                                                      disabled: styles.disabledBox
                                                    }}
                                                    checked={selectedDevices.includes(system._id)}
                                                    onChange={handleChangeDevice}
                                                    required
                                                    outline='true'
                                                    inputRef={register()}
                                                    disableRipple
                                                    disabled={
                                                      selectedDevices.length > 39 &&
                                                      !selectedDevices.includes(system._id)
                                                    }
                                                    name={`selectedDevices[]`}
                                                    value={system._id}
                                                  />
                                                }
                                                label={system.name}
                                              />
                                            </div>
                                          )
                                        })}
                                      </Collapse>
                                    </div>
                                  )
                                })}
                              </Collapse>
                            )}
                          </div>
                        )
                      })}
                    </List>
                  </Paper>

                  <p className={styles.deviceNumber}>
                    <span className={styles.hover_visible}>
                      <img
                        src='../images/info.svg'
                        className={cx('img_info', styles.infoIcon)}
                        alt=''
                      />
                      <div className={styles.information_box}>
                        <div className={styles.information}>
                          <h4 className={styles.indicatorInformationTitle}>
                            {translate('addReportModal.selectedDevice')}
                          </h4>
                          <p className='mt-2'>{translate('addReportModal.deviceNumber')}</p>
                        </div>
                      </div>
                    </span>
                    {translate('selectedDevices') + selectedDevices.length}
                  </p>
                  {errors.selectedDevices && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyDeviceRequired')}</small>
                    </div>
                  )}
                  {errors.selectedDevicesTime && (
                    <div className={styles.error}>
                      <small>{translate('validation.timeOutDevices')}</small>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente1}
                    onClick={onClickNext}
                  >
                    {translate('addReportModal.next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickCancel}
                  >
                    {translate('addReportModal.cancel')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
              <div className={styles.secondPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addReportModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navInterval')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navOccupation')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('addReportModal.selectParameters')}
                  </Typography>
                </div>
                <FormControlLabel
                  className={styles.selectAllCheckboxForm}
                  control={
                    <Checkbox
                      className={cx(styles.checkbox)}
                      classes={{ checked: styles.checkedBox }}
                      name='selectAll'
                      id='selectAll'
                      indeterminate={isIndeterminate()}
                      onChange={handleSelectAll}
                      checked={isCheckAll}
                    />
                  }
                  label={translate('downloadDataForm.selectAll')}
                />
                <div className={styles.checkboxWrapper}>
                  {type.map((dataType, i) => {
                    return (
                      <div
                        key={i + 'typeCheckbox'}
                        className={styles.checkboxFormDiv}
                      >
                        <FormControlLabel
                          className={styles.checkboxForm}
                          control={
                            <Checkbox
                              className={cx(styles.checkbox)}
                              classes={{ checked: styles.checkedBox }}
                              onChange={handleChange}
                              checked={typeChecked.includes(dataType)}
                              required
                              name={`types[${i}]`}
                              value={dataType}
                              inputRef={register()}
                              disableRipple
                            />
                          }
                          label={translate(dataType)}
                        />
                      </div>
                    )
                  })}
                </div>
                <div className={styles.wrapperError}>
                  {errors.types && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyFieldRequired')}</small>
                    </div>
                  )}
                </div>
                <div
                  className={styles.buttonWrapper}
                  style={{ marginTop: type.length < 9 ? '59.5%' : '37%' }}
                >
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente2}
                    onClick={onClickNext}
                  >
                    {translate('addReportModal.next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('addReportModal.back')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 2 ? 'block' : 'none' }}>
              <div className={styles.secondPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheaderDevices}>
                    {translate('addReportModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addReportModal.navInterval')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navOccupation')}
                  </Typography>
                </div>
                <div>
                  <Typography className={styles.subInfo}>
                    {translate('addReportModal.selectDateInterval')}
                  </Typography>
                </div>
                <div className={styles.alligned}>
                  <FormControlLabel
                    onKeyPress={e => {
                      e.key === 'Enter' && e.preventDefault()
                    }}
                    className={styles.checkboxForm}
                    value={'auto'}
                    onChange={handleChangeOption}
                    control={
                      <Radio
                        className={cx(styles.checkbox, styles.radioItem)}
                        classes={{ checked: styles.checkedBox }}
                        inputRef={register()}
                        disableRipple
                        checked={optionValue === 'auto'}
                        value={'auto'}
                      />
                    }
                    label={translate('automatic')}
                  />
                  <div className={styles.selectBorder}>
                    <select
                      className={
                        optionValue === 'auto' ? styles.selectRange : styles.selectRangeGray
                      }
                      onChange={onRangeChange}
                      disabled={optionValue === 'manual'}
                    >
                      <option value='1w'>1 {translate('week')}</option>
                      <option value='2w'>2 {translate('weeks')}</option>
                      <option value='1M'>1 {translate('month')}</option>
                      {selectedDevices.length < 35 && (
                        <option value='2M'>2 {translate('months')}</option>
                      )}
                    </select>
                  </div>
                </div>
                <FormControlLabel
                  onKeyPress={e => {
                    e.key === 'Enter' && e.preventDefault()
                  }}
                  className={styles.checkboxForm}
                  value={'manual'}
                  onChange={handleChangeOption}
                  control={
                    <Radio
                      className={cx(styles.checkbox, styles.radioItem)}
                      classes={{ checked: styles.checkedBox }}
                      inputRef={register()}
                      checked={optionValue === 'manual'}
                      disableRipple
                      value={'manual'}
                    />
                  }
                  label={translate('manual')}
                />
                <div className={styles.datePickerWrapper}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={locale}
                  >
                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={optionValue === 'auto' ? styles.subInfo : styles.subInfoDisabled}
                      >
                        {translate('addReportModal.startDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <KeyboardDatePicker
                          InputProps={{ readOnly: true }}
                          disableToolbar
                          disableFuture
                          variant='inline'
                          margin='0'
                          inputRef={register()}
                          autoOk
                          name='startDatePicker'
                          className={cx(styles.datePicker, {
                            [styles.hasError]: errors.startDate
                          })}
                          format='dd/MM/yyyy'
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          shouldDisableDate={shouldDisableDate}
                          value={startDate}
                          onChange={newValue => setStarDate(newValue)}
                          disabled={optionValue === 'auto'}
                        />
                      </ThemeProvider>
                    </div>

                    <div className={styles.picker}>
                      <Typography
                        variant='subtitle2'
                        className={optionValue === 'auto' ? styles.subInfo : styles.subInfoDisabled}
                      >
                        {translate('addReportModal.endDate')}
                      </Typography>
                      <ThemeProvider theme={customTheme}>
                        <KeyboardDatePicker
                          InputProps={{ readOnly: true }}
                          disableToolbar
                          disableFuture
                          variant='inline'
                          inputRef={register()}
                          format='dd/MM/yyyy'
                          autoOk
                          margin='0'
                          name='endDatePicker'
                          className={cx(styles.datePicker, {
                            [styles.hasError]: errors.rangeDate
                          })}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          shouldDisableDate={shouldDisableEndDate}
                          value={endDateState}
                          onChange={newValue => setEndDateState(newValue)}
                          disabled={optionValue === 'auto'}
                        />
                      </ThemeProvider>
                      {errors.rangeDate && errors.rangeDate?.type === 'required' && (
                        <div className={styles.error}>
                          <small>{translate('validation.required')}</small>
                        </div>
                      )}
                      {errors.rangeDate && (
                        <div className={styles.error}>
                          <small>{translate('validation.invalidDateRange')}</small>
                        </div>
                      )}
                    </div>
                  </MuiPickersUtilsProvider>
                  <p className={styles.deviceNumber}>
                    <span className={styles.hover_visible}>
                      <img
                        src='../images/info.svg'
                        className={cx('img_info', styles.infoIcon)}
                        alt=''
                      />
                      <div className={styles.information_box}>
                        <div className={styles.information}>
                          <h4 className={styles.indicatorInformationTitle}>
                            {translate('addReportModal.rangeTimeDisponible')}
                          </h4>
                          <p className='mt-2'>{translate('addReportModal.rangeTimeInfo')}</p>
                        </div>
                      </div>
                    </span>
                    {translate('addReportModal.rangeTimeDisponible') +
                      ': ' +
                      selectedWeeks() +
                      ' ' +
                      translate('weeks')}
                  </p>
                </div>
                <div
                  className={styles.buttonWrapper}
                  style={{ marginTop: type.length < 9 ? '59.5%' : '37%' }}
                >
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonSiguiente2}
                    onClick={onClickNext}
                  >
                    {translate('addReportModal.next')}
                    <ArrowForward className={styles.arrow} />
                  </Button>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    disableRipple
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('addReportModal.back')}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ display: activeTab === 3 ? 'block' : 'none' }}>
              <div className={styles.thirdPage}>
                <div className={styles.nav}>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navDevices')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navParams')}
                  </Typography>
                  <Typography className={styles.subheader}>
                    {translate('addReportModal.navInterval')}
                  </Typography>
                  <Typography className={styles.subheaderSelected}>
                    {translate('addReportModal.navOccupation')}
                  </Typography>
                </div>
                <div className={styles.wrapperDefaultValues}>
                  <div className={styles.subInfo}>
                    <Typography className={styles.occupationInterval}>
                      {translate('addReportModal.occupationInterval')}
                    </Typography>
                  </div>
                </div>
                <div className={styles.paramWrapper}>
                  <div className={styles.wrapperDefaultValues}>
                    <div className={styles.subInfo}>
                      <Typography variant='subtitle6'>
                        {translate('addReportModal.occupationDay')}
                      </Typography>
                    </div>
                  </div>
                  <div className={styles.wrapperDefaultValues}>
                    <FormControlLabel
                      className={styles.selectAllCheckboxForm}
                      control={
                        <Checkbox
                          className={cx(styles.checkbox)}
                          classes={{ checked: styles.checkedBox }}
                          name='selectAllDay'
                          indeterminate={isIndeterminateDay()}
                          id='selectAllDay'
                          onChange={handleSelectAllDays}
                          isChecked={isCheckAllDay}
                        />
                      }
                      label={translate('addReportModal.selectAll')}
                    />
                  </div>

                  <table className={styles.checkboxFormDivA}>
                    <tbody>
                      <tr>
                        {daysTranslated.map((column, index) => (
                          <td
                            key={index + 'daysTranslated'}
                            className={styles.subInfo1}
                          >
                            {column}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        {days.map((column, index) => (
                          <td
                            key={'day' + index}
                            className={styles.subInfo1}
                          >
                            <FormControlLabel
                              className={styles.checkboxForm}
                              control={
                                <Checkbox
                                  className={cx(styles.checkboxAligned)}
                                  classes={{ checked: styles.checkedBox }}
                                  checked={typeCheckedBox[column] === true}
                                  required
                                  name={column}
                                  value={column}
                                  onChange={handleChangeCheckbox}
                                  inputRef={register()}
                                  disableRipple
                                  rules={{ required: true, validate: downloadReport }}
                                />
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>

                  {errors.noChecked && (
                    <div className={styles.error}>
                      <small>{translate('validation.anyField')}</small>
                    </div>
                  )}
                  <div className={styles.wrapperDefaultValues}>
                    <div className={styles.subInfoSchedule}>
                      <div className={styles.schedule}>
                        <Typography variant='subtitle6'>
                          {translate('addReportModal.occupationSchedule')}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className={styles.occupationHourRow}>
                    <Typography className={styles.occupationHourTitle}>
                      {translate('addReportModal.firstOccupationHours')}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={['TimePicker']}>
                        <div className={styles.firstOccupancyTime}>
                          <div
                            className={styles.timePickerContent}
                            onClick={() => handleClickPicker(1)}
                          >
                            <DesktopTimePicker
                              timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                              components={{ ActionBar: 'none' }}
                              value={startSelectedDate}
                              onChange={newValue => setStartSelectedDate(newValue)}
                              open={openPicker && isActive === 1}
                              onClose={handleClose}
                              className={cx(styles.timePicker, classes.inp)}
                            />
                          </div>
                          <span>-</span>
                          <div
                            className={styles.timePickerContent}
                            onClick={() => handleClickPicker(2)}
                          >
                            <DesktopTimePicker
                              timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                              components={{ ActionBar: 'none' }}
                              open={openPicker && isActive === 2}
                              value={selectedDate}
                              onChange={newValue => setSelectedDate(newValue)}
                              onClose={handleClose}
                              className={cx(styles.timePicker, classes.inp)}
                            />
                          </div>
                        </div>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>

                  <div className={styles.occupationHourRow}>
                    <div className={styles.activateSecondOccupationHoursWrapper}>
                      <Typography className={styles.occupationHourTitle}>
                        {translate('addReportModal.secondOccupationHours')}
                      </Typography>
                      <Switch
                        checked={secondOccupationHoursChecked}
                        onChange={handleChangeSecondOccupationHoursChecked}
                        name='checkSecondOccupationHours'
                        color='primary'
                        inputProps={{ 'aria-label': 'controlled' }}
                        className={cx(styles.switch, classes.switch)}
                      />
                    </div>
                    <div className={styles.occupationHourCheckRow}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DemoContainer components={['TimePicker']}>
                          <div className={styles.secondOccupancyTime}>
                            <div
                              className={styles.timePickerContent}
                              onClick={() => handleClickPicker(3)}
                              disabled={!secondOccupationHoursChecked}
                            >
                              <DesktopTimePicker
                                timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                                components={{ ActionBar: 'none' }}
                                open={openPicker && isActive === 3}
                                value={startSelectedDate1}
                                onChange={newValue => setStartSelectedDate1(newValue)}
                                onClose={handleClose}
                                className={cx(styles.timePicker, classes.inp)}
                                disabled={!secondOccupationHoursChecked}
                              />
                            </div>
                            <span>-</span>
                            <div
                              className={styles.timePickerContent}
                              onClick={() => handleClickPicker(4)}
                              disabled={!secondOccupationHoursChecked}
                            >
                              <DesktopTimePicker
                                timeSteps={{ hours: 1, minutes: 30, seconds: 5 }}
                                components={{ ActionBar: 'none' }}
                                open={openPicker && isActive === 4}
                                onClose={handleClose}
                                value={selectedDate1}
                                onChange={newValue => setSelectedDate1(newValue)}
                                className={cx(styles.timePicker, classes.inp)}
                                disabled={!secondOccupationHoursChecked}
                              />
                            </div>
                          </div>
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                    {errors.fourDates && (
                      <div className={styles.error}>
                        <small>{translate('validation.timeRange')}</small>
                      </div>
                    )}
                  </div>
                </div>

                {renderError()}
                <div className={styles.buttonWrapper}>
                  <LoadingButton
                    type='submit'
                    fullWidth
                    loading={isSubmitting}
                    loadingPosition='end'
                    variant='contained'
                    className={styles.buttonSiguiente}
                    onClick={onClickDownload}
                  >
                    {translate('addReportModal.downloadReport')}
                  </LoadingButton>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button
                    fullWidth
                    variant='contained'
                    className={styles.buttonCancelar}
                    onClick={onClickBack}
                    disabled={isSubmitting}
                  >
                    <ArrowBack className={styles.arrow} />
                    {translate('addReportModal.back')}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </StylesProvider>
  )
}

AddReportModalForm.propTypes = {
  translate: PropTypes.func.isRequired
}

export default withLocalize(AddReportModalForm)
