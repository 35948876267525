// -------------- External imports ---------------- //
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// -------------- Material-UI imports ---------------- //
import { Modal, Button, Paper } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Map, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import axios from 'axios'
// -------------- Internal imports ---------------- //
import styles from './MapModal.module.scss'
import { MAPBOX_API_KEY } from 'config'
const MAP_TOKEN = MAPBOX_API_KEY
const GOOGLE_MAPS_MARKER = 'https://maps.google.com/mapfiles/kml/paddle/red-circle.png' // Google Maps-style marker

const MapModal = props => {
  const { hideModal, translate, initialMarkerLocation, changeAdress, fullAdress } = props

  const [open] = useState(true)
  const [searchQuery, setSearchQuery] = useState('') // Stores the user's search query
  const [viewport, setViewport] = useState({
    latitude: 40.0, // Center of Spain
    longitude: -3.7, // Center of Spain
    zoom: 5 // Zoom level to cover all of Spain
  })
  const [markerLocation, setMarkerLocation] = useState(initialMarkerLocation || null) // Stores the marker position
  const [locationAddress, setLocationAddress] = useState(fullAdress || '') // Stores the address of the selected location

  const getMapHeight = () => {
    return window.innerWidth < 600 ? '61vh' : '80vh' // Change height based on screen width
  }
  const [mapHeight, setMapHeight] = useState(getMapHeight())
  useEffect(() => {
    const handleResize = () => {
      setMapHeight(getMapHeight())
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (initialMarkerLocation) {
      setViewport({
        latitude: initialMarkerLocation.latitude,
        longitude: initialMarkerLocation.longitude,
        zoom: 13 // Adjust the zoom level if necessary
      })
      setMarkerLocation(initialMarkerLocation)
    }
  }, [initialMarkerLocation])
  const handleConfirmClick = () => {
    if (markerLocation) {
      props.onConfirm(markerLocation)
      changeAdress(locationAddress)
      hideModal()
    } else {
      alert('Please select a location')
    }
  }
  const handleSearch = async () => {
    if (searchQuery) {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchQuery}.json?access_token=${MAP_TOKEN}`
      )
      const data = response.data
      if (data && data.features && data.features.length > 0) {
        const location = data.features[0]
        const [lon, lat] = location.center
        setLocationAddress(location.place_name)
        setMarkerLocation({ latitude: lat, longitude: lon }) // Set marker to search result
        setViewport({
          latitude: lat,
          longitude: lon,
          zoom: 13
        })
      } else {
        alert('Location not found')
      }
    }
  }
  const handleMapClick = async event => {
    const { lng, lat } = event.lngLat
    setMarkerLocation({ latitude: lat, longitude: lng })
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAP_TOKEN}`
    )
    const data = response.data
    if (data && data.features && data.features.length > 0) {
      const address = data.features[0].place_name
      if (address) {
        setLocationAddress(address)
      }
      // Deselect any previous location
    } else {
      setLocationAddress(String(lat) + lng)
    }
  }

  const handleMouseEnter = () => {
    document.body.style.cursor = 'pointer'
  }

  const handleMouseLeave = () => {
    document.body.style.cursor = 'default'
  }
  const StyledPaper = withStyles({
    root: {
      maxHeight: '100% !important',
      'overflow-y': 'visible',
      border: '2px solid #dddddd',
      verticalAlign: 'center !important',
      borderRadius: '32px',
      backgroundColor: '#ffffff',
      display: 'flex'
    }
  })(props => <Paper {...props} />)

  return (
    <Modal
      onClose={hideModal}
      open={open}
      fullWidth
      arial-labelledby='simple-modal-title'
      arial-desccribedby='simple-modal-description'
      scroll='body'
      BackdropProps={{
        classes: {
          root: styles.backdrop
        }
      }}
      classes={{
        root: styles.root
      }}
      PaperComponent={StyledPaper}
    >
      <div className={styles.flexModalContainer}>
        <div className={styles.modalContainer}>
          <div className={styles.content}>
            <div>
              {/* Search Bar */}
              <div
                className={styles.alligned}
                style={{ marginBottom: '10px' }}
              >
                <input
                  type='text'
                  className={styles.searchBar}
                  placeholder={translate('searchAdress')}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                />
                <Button
                  disableRipple
                  onClick={handleSearch}
                  variant='contained'
                  className={styles.buttonSiguiente}
                >
                  Search
                </Button>
              </div>

              {/* Map */}
              <Map
                {...viewport} // Spread the current viewport settings
                style={{ width: '100%', height: mapHeight }}
                mapStyle='mapbox://styles/mapbox/streets-v11'
                mapboxAccessToken={MAP_TOKEN}
                onMove={evt => setViewport(evt.viewState)} // Update viewport on map move
                onClick={handleMapClick} // Place marker on map click
                cursor='pointer'
                attributionControl={false} // Hide Mapbox attribution
              >
                {/* Display marker for the selected location or clicked location */}
                {markerLocation && (
                  <Marker
                    latitude={markerLocation.latitude}
                    longitude={markerLocation.longitude}
                    anchor='bottom'
                  >
                    <img
                      src={GOOGLE_MAPS_MARKER} // Google Maps-style marker
                      alt='Marker'
                      style={{ width: '40px', height: '40px', transform: 'translateY(0px)' }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    />
                  </Marker>
                )}
              </Map>

              {/* Display the coordinates of the marker below the map */}
            </div>
            <div className={styles.footerButtons}>
              <Button
                onClick={hideModal}
                className={styles.buttonCancel}
              >
                {translate('cancel')}
              </Button>
              <Button
                disableRipple
                variant='contained'
                onClick={handleConfirmClick}
                className={styles.buttonSiguienteSave}
              >
                {translate('save')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

MapModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  contentText: PropTypes.string,
  title: PropTypes.string,
  buttonName: PropTypes.string
}

export default MapModal
