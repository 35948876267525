export const FULL_SCREEN = 'FULL_SCREEN'
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL'
export const DOWNLOAD_SETTINGS = 'DOWNLOAD_SETTINGS'
export const DOWNLOAD_MULTIPLE = 'DOWNLOAD_MULTIPLE'
export const DOWNLOAD_STATISTICS = 'DOWNLOAD_STATISTICS'
export const FULL_SCREEN_SETTINGS = 'FULL_SCREEN_SETTINGS'
export const ADD_SYSTEM = 'ADD_SYSTEM'
export const EDIT_SYSTEM = 'EDIT_SYSTEM'
export const EDIT_LIGHTS = 'EDIT_LIGHTS'
export const EDIT_PERIODICITY = 'EDIT_PERIODICITY'
export const ADD_PARAMETER = 'ADD_PARAMETER'
export const EDIT_PARAMETER = 'EDIT_PARAMETER'
export const ADD_INACTIVITY = 'ADD_INACTIVITY'
export const EDIT_INACTIVITY = 'EDIT_INACTIVITY'
export const ADD_FULLSCREEN = 'ADD_FULLSCREEN'
export const EDIT_FULLSCREEN = 'EDIT_FULLSCREEN'
export const NEW_GROUP = 'NEW_GROUP'
export const EDIT_GROUP = 'EDIT_GROUP'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const TYPE_INFO = 'TYPE_INFO'
export const TYPE_PARAMETER = 'TYPE_PARAMETER'
export const SUPPORT_MODAL = 'SUPPORT_MODAL'
export const WHATS_NEW = 'WHATS_NEW'
export const SMART_REPORT = 'SMART_REPORT'
export const CALIBRATION_REPORT = 'CALIBRATION_REPORT'
export const DOWNLOAD_WELL_DATA_REPORT = 'WELL_REPORT'
export const DOWNLOAD_WELL_STATIC_DATA = 'WELL_STATIC'
export const TRANSFER_SYSTEM = 'TRANSFER_SYSTEM'
export const DELETE_USER_SYSTEM = 'DELETE_USER_SYSTEM'
export const CONFIRM_TRANSFER = 'CONFIRM_TRANSFER'
export const ADD_COLLABORATIVE = 'ADD_COLLABORATIVE'
export const EDIT_CALIBRATIONCO2 = 'EDIT_CALIBRATIONCO2'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const WEATHER_MODAL = 'WEATHER_MODAL'
